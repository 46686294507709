import {
  Avatar,
  Col,
  List,
  Modal,
  Row,
  Spin,
  Table,
  TableProps,
  Typography,
} from "antd";
import ProjectDetail from "./ProjectCards/ProjectDetail";
import "../../common/common.css";
import PendingTask from "./PendingTask/PendingTask";
import TaskBoard from "./TaskBoard/TaskBoard";
import TaskReportdash from "../Taskreportdashbord/TaskReportdash";
import { useEffect, useState } from "react";
import BooksService from "../../Services/Books";
import { useSelector } from "react-redux";
import { userData } from "../../store/slices/user-slice";
import { Link, useLocation } from "react-router-dom";
// import Loader from "../../components/commoncomponents/Loader";
import { projectData } from "../../store/slices/project-slice";
import { LoadingOutlined, UserOutlined } from "@ant-design/icons";
import TableSkeleton from "./Common/TableSkeleton";
import { getAvatarUrl } from "../../utils/Util";
import { AppRoutesPath } from "../../Routes/AppRoutesPath";
import ActiviyLogsDashboard from "./ActiviyLogs/ActiviyLogsDashboard";
const Dashboard = (props: any) => {
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const [projectdata, setprojectdata] = useState<any>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [retryLoading, setRetryLoading] = useState(true);
  const [linechartprojectdata, setlinechartprojectdata] = useState<
    { label: string; value: string }[]
  >([]);
  const [projectidcirculargraph, setprojectidcirculargraph] = useState<any>(
    project?.id
  );
  const [assigneedata, setassigneedata] = useState<any>(null);
  const [assigneechartdata, setassigneechartdata] = useState<any>(null);
  const [alltaskchartrefresh, setalltaskchartrefresh] = useState(false);
  const [priorityselect, setpriorityselect] = useState<any>(null);
  const [activitydata, setactivitydata] = useState([]);
  const [comid, setcomid] = useState(false);
  const [pendingdata, setpendingdata] = useState<any>(null);

  const [lateststate, setlateststate] = useState<any>(null);
  const location = useLocation();
  const [countresponse, setcountresponse] = useState<any>(null);
  const [projectIds, setProjectIds] = useState<any>("");
  const [show, setShow] = useState<string>("");
  const [selectedAssignees, setSelectedAssignees] = useState([]);
  const [ReloadOutlined, setReloadOutlined] = useState(0);
  const state: any = location.state as {
    bookid: string;
    name: string;
    email: string;
    role: string;
    id: string;
    imageThumb: string;
  };
  const fetchProjects = async () => {
    try {
      setIsLoading(true);
      let res = await BooksService.getProjectByUser(user?.id);
      setprojectdata(res?.result);

      let userdatatype: any = [];

      res?.result.map((item: any, index: number) => {
        userdatatype.push({ label: item?.name, value: item?.id });
      });
     
      
      setlinechartprojectdata(userdatatype);
      BooksService.getDashboardByProject(
        projectidcirculargraph === null
          ? userdatatype[0].value
          : projectidcirculargraph
      ).then((res: any) => {
        if (res?.result?.length === 0) {
          setassigneechartdata([]);
        } else {
          setassigneechartdata(res?.result?.items);
        }
      });
      setRetryLoading(false);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchactivity = async () => {
    try {
      setIsLoading(true);
      let res = await BooksService.getlogByprojectid(15, "", user?.id, 0);
      setactivitydata(res?.result);
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (location.state) {
      setcomid(state?.bookId);
    }
  }, [comid, ReloadOutlined]);

  useEffect(() => {
    if (!retryLoading) {
      return;
    }
  }, [comid, ReloadOutlined]);

  useEffect(() => {
    fetchactivity();
  }, [localStorage.getItem("createtask"), ReloadOutlined]);

  const getassigneedata = async () => {
    try {
      setIsLoading(true);
      let res = await BooksService.getTaskByAssignee(user?.id, 1);
      if (res.result.length === 0) {
        setassigneedata([]);
      } else {
        setassigneedata(res?.result);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  const getpendingdata = async () => {
    try {
      setIsLoading(true);
      let res = await BooksService.getpendingTasks(user?.id);
      if (res.result.items.length === 0) {
        setpendingdata([]);
      } else {
        setpendingdata(res.result.items);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
    }
  };

  //StatusTotalRecord Task Reportselect Api
  const fetchdashboard = async () => {
    try {
      setIsLoading(true);
      let res = await BooksService.getTaskByassingeeidandprojectid(
        projectIds,
        ""
      );
      if (res?.result?.items?.length === 0) {
        setcountresponse([]);
      } else {
        setcountresponse(res?.result?.items);
      }
    } catch (err) {
      console.error("Error fetching dashboard data:", err);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    getassigneedata();
    getpendingdata();
    fetchdashboard();
  }, [lateststate, ReloadOutlined, projectIds]);

  // useEffect(() => {
  //   setSwitchData("meeting");
  // }, [reloading]);

  useEffect(() => {
    fetchProjects();
  }, [lateststate, ReloadOutlined, projectidcirculargraph]);

  useEffect(() => {
    fetchactivity();
  }, [ReloadOutlined]);

  useEffect(() => {}, [
    projectidcirculargraph,
    priorityselect,
    lateststate,
    ReloadOutlined,
  ]);
  const columns = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "4%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{text}</div>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      // showSorterTooltip: false,
      width: "20%",
      sorter: (a: any, b: any) => a.Name - b.Name,
      render: (item: any, record: any) => (
        <List itemLayout="horizontal" size="small">
          <List.Item
            key={record?.id}
            style={{ padding: 0, alignItems: "center" }}
          >
            <List.Item.Meta
              style={{ padding: 0, alignItems: "center" }}
              key={`key-${record?.id}`}
              avatar={
                <Avatar
                  icon={<UserOutlined />}
                  size={"small"}
                  src={getAvatarUrl(record)}
                  alt="user"
                />
              }
              title={
                <Link
                  to={`/${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`}
                  state={{ userid: record?.id }}
                >
                  <Typography.Paragraph
                    title={record?.name}
                    ellipsis
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    {record?.name}
                  </Typography.Paragraph>
                </Link>
              }
            />
          </List.Item>
        </List>
      ),
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 7,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });

    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["7", "10", "15", "20", "40"];

 


  return (
    <>
      <Spin
        spinning={isLoading}
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        {/* <Loader loading={isLoading} /> */}

        <div style={{ marginTop: "5px" }}>
          <Row gutter={[24, 16]} className="projectListRow">
            <Col lg={16} xl={18} xxl={19}>
              <Row>
                <Col span={24} md={24} className="projectListRow mb-2">
                  <ProjectDetail
                    projectdata={projectdata}
                    setShow={setShow}
                    setSelectedAssignees={setSelectedAssignees}
                    setIsLoading={setIsLoading}
                    setReloadOutlined={setReloadOutlined}
                  />
                </Col>
              </Row>
              <Row gutter={[15, 50]} justify={"space-between"}>
                <Col xl={12} md={24} sm={24}>
                  <TaskReportdash
                    y={"31vh"}
                    page="TaskReportdash"
                    projectdata={projectdata}
                    linechartprojectdata={linechartprojectdata}
                    assigneechartdata={assigneechartdata}
                    retryLoading={retryLoading}
                    setlateststate={setlateststate}
                    setReloadOutlined={setReloadOutlined}
                    setprojectidcirculargraph={setprojectidcirculargraph}
                    priorityselect={priorityselect}
                    setpriorityselect={setpriorityselect}
                    assigneedata={assigneechartdata}
                    setalltaskchartrefresh={setalltaskchartrefresh}
                    setProjectIds={setProjectIds}
                    charttype={"tasks"}
                  />
                </Col>
                <Col xl={12} md={24} sm={24}>
                  <PendingTask
                    page="PendingTask"
                    props={undefined}
                    projectdata={undefined}
                    setShow={setShow}
                    setSelectedAssignees={setSelectedAssignees}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg={24} xl={24} className="mt-5">
                  <Col lg={24} xl={24} xs={24} md={24}>
                    <TaskBoard
                      page="TaskBoard"
                      setassigneedata={setassigneedata}
                      assigneedata={assigneedata}
                    />
                  </Col>
                </Col>
              </Row>
            </Col>

            <Col
              lg={8}
              xl={6}
              xxl={5}
              xs={24}
              md={24}
              sm={24}
              style={{
                paddingLeft: 0,
                paddingRight:6
              }}
            >
              <div
                style={{
                  wordBreak: "break-all",
                }}
                className="ResponsiveHide"
              >
                <ActiviyLogsDashboard
                  // y={"85vh"}
                  page="dashboard"
                  params={{ assigneeid: user?.id }}
                />
              </div>
            </Col>
          </Row>
        </div>

        {show === "Assignees" && (
          <Modal
            title="Assignee List"
            open={!!show}
            onCancel={() =>
              setTimeout(() => {
                setShow("");
              }, 100)
            }
            footer={null}
          >
            {isLoading ? (
              <TableSkeleton
                columns={columns}
                scroll={{ y: "75vh" }}
                style={{
                  scrollbarWidth: "none",
                  borderTop: "solid 1px #b2bec3",
                }}
              />
            ) : (
              <Table
                className="capTask-custom-item"
                scroll={{ y: "255px" }}
                style={{
                  border: "1px solid #dfe6e9",
                  borderRadius: 3,
                  padding: "12px 7px",
                }}
                onChange={onChangeTable}
                pagination={{
                  showTotal: (total: any, range: any) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  current: listParams.start / listParams.length + 1,
                  pageSize: listParams.length,
                  showSizeChanger: true,
                  pageSizeOptions: pageSizeOption,
                  position: ["bottomRight"],
                }}
                dataSource={selectedAssignees?.map(
                  (data: any, index: number) => ({
                    serial: index + 1,
                    key: index,
                    ...data,
                  })
                )}
                columns={columns}
                rowKey="id"
              />
            )}
          </Modal>
        )}
      </Spin>
    </>
  );
};

export default Dashboard;
