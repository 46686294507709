import React from "react";
import "./index.css";
import "antd-css-utilities/utility.min.css";
import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ConfigProvider, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { resetUserData, userData } from "./store/slices/user-slice";
import { isTokenNotExpired } from "./common/commonFunction";
import ApplicationRoutes from "./Routes/ApplicationRoutes";

function App() {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userData);
  const dispatch = useDispatch();

  useEffect(() => {
    const normalizedPath = location.pathname.replace(/\/$/, ""); // Remove trailing slash if present
    if (user?.token) {
      
      if (isTokenNotExpired(user.token)) {
        if (location.pathname === "/" || normalizedPath === "/login") {
          navigate("/dashboard");
        }
      } else {
        dispatch(resetUserData());
        navigate("/login", { replace: true });
      }
    } else if (location.pathname === "/") {
      navigate("/login", { replace: true });
    } else {
      if (location.pathname === "/forgotpassword" || location.pathname==="/accountrecovery/ResetPassword") {
      } else {
         navigate("/login");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navigate, user]);

  const [, contextHolder] = message.useMessage();
  return (
    <ConfigProvider
      // theme={{ token: { fontFamily: "Poppins" } }}
      theme={{
        components: {
          Menu: {
            darkSubMenuItemBg: " rgb(253, 12, 148)",
            darkItemBg: "rgb(39, 0, 41)",
            colorText: "#001440",
            fontSize: 15,
            fontWeightStrong: 900,
            borderRadius: 0,
            borderRadiusLG: 0,
            margin: 0,
            marginXS: 0,
            padding: 0,
            paddingXL: 0,
            paddingXS: 0,
            itemMarginBlock: 8,
            itemMarginInline: 0,
            itemHoverBg: "transparent",
            itemActiveBg: "transparent",
            colorFill: "red",
            // itemBg: "#EBF3FC",
          },
          // Menu: {
          // radiusItem: 0,
          // itemBorderRadius: 0,
          // radiusSubMenuItem: 0,
          // subMenuItemBorderRadius: 0,
          // itemColor: "white",
          // itemHoverColor: "white",
          // horizontalItemHoverColor: "#1677ff",
          // groupTitleColor: "white",
          // itemSelectedColor: "white",
          // horizontalItemSelectedColor: "#1677ff",
          // itemHoverBg: "#002a52",
          // itemActiveBg: "#1F77FB",
          // subMenuItemBg: "#000c17",
          // itemSelectedBg: "#1F77FB",
          // activeBarHeight: 2,
          // itemMarginInline: 0,
          // itemHeight: 35,
          // itemMarginBlock: 8,
          // itemPaddingInline: 16,
          // horizontalLineHeight: "46px",
          // iconSize: 17,
          // iconMarginInlineEnd: 20,
          // groupTitleFontSize: 12,
          // fontSize: 14,
          // fontWeightStrong: 800,
          // darkItemHoverBg: "#002a52",
          // darkItemColor: "white",
          // },
          Layout: {
            headerBg: "white",
            headerPadding: "0px 20px",
            bodyBg: "#f3f4fa",
            footerBg: "#f3f4fa",
          },

          // Table: {
          //   // fontSize: 14,
          //   fontWeightStrong: 500,
          //   // lineHeight: 1,
          //   // lineWidth: 0,
          //   // lineType: "dashed",
          //   padding: 6,
          //   paddingContentVerticalLG: 6,
          //   algorithm: true,
          //   colorFillAlter: "#F8FAFE",

          //   // borderRadius: 0,
          //   // algorithm: true,
          // },
          Table: {
            headerBorderRadius: 0,
            borderRadius: 0,
            cellPaddingBlock: 4,
            rowHoverBg: "#fafafa",
           
          },
          Badge: {
            colorSuccess: "#40d824",
            colorWarning: "#FF9100",
            colorError: "#FF0000",
            algorithm: true,
          },
          Button: {
            fontSize: 13,
            // colorText: 'white',
            // defaultBg: '#1162FF'
            // borderRadius: 4,
          },
          Input: {
            colorTextPlaceholder: "#95a5a6",
            colorFillAlter: "transparent",
            borderRadius: 4,
            fontSize: 13,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
          },
          DatePicker: {
            colorTextPlaceholder: "#95a5a6",
            algorithm: true,
            fontSizeIcon: 10,
            // fontSize: 13,
            // controlHeight: 28,
            // borderRadius: 1,
            borderRadius: 4,
          },
          Select: {
            colorTextPlaceholder: "#95a5a6",
            fontSize: 14,
            colorPrimaryHover: "none",
            // controlHeight: 32,
            fontSizeIcon: 10,
            // borderRadius: 4,
            paddingSM: 0,
            paddingXS: 0,
            paddingXXS: 0,
            lineWidth: 1,
            borderRadius: 0,
            borderRadiusLG: 0,
            borderRadiusSM: 0,
            borderRadiusXS: 0,
            controlPaddingHorizontal: 4,
            controlPaddingHorizontalSM: 4,
            controlHeight: 24,
            controlHeightLG: 24,

            // fontSize: 14,
            // controlHeight: 28,
            // borderRadius: 1,
            algorithm: true,
            fontWeightStrong: 600,
          },
          Pagination: {
            // borderRadius: 1,
            algorithm: true,
            itemSize: 28,
            fontSize: 13,
            margin: 4,
            lineType: "",
            colorBgTextHover: "transparent",
            colorBgTextActive: "transparent",
          },
          Modal: {
            // titleFontSize: 12
          },
          Tooltip: {
            colorBgSpotlight: "#FFFFFF",
            colorTextLightSolid: "#0d1b2a",
          },
          Avatar: {
            fontWeightStrong: 600,
            size: 20,
            colorTextPlaceholder: "#EBF1FF",
            colorTextLightSolid: "black",
            // groupBorderColor:"red"
          },
          List: {
            itemPaddingSM: "5px 0px",
          },
          Skeleton: {
            algorithm: true,
            controlHeight: 10,
            controlHeightLG: 10,
            controlHeightSM: 10,
            controlHeightXS: 10,
            marginSM: 1,
            padding: 1,
            borderRadiusSM: 4,
          },
        },

        // token: { fontFamily: "Segoe UI", fontSize: 14 },
        token: {
          fontFamily: "Open Sans, sans-serif",
          // fontFamily: "Avenir , sans-serif",
          // fontFamily: "Inter",
          // fontFamily: "Verdana",
          // fontFamily: "Readex Pro",
          // fontFamily:
          //   "-apple-system,,Open Sans, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol','Noto Color Emoji'",
        },
      }}
    >
      {/* fallback={<FullPageSpinner />} */}
      {contextHolder}
      <React.Suspense>
        <ApplicationRoutes />
      </React.Suspense>
    </ConfigProvider>
  );
}

export default App;
