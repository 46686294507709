import React, { useState } from "react";
import AddEditProject from "./AddEditProject";
import { Breadcrumb, Button, Col, Input, Row, Table, TableProps, Tooltip, Typography } from "antd";
import { useGetProjects } from "../../Services/RQDashboardService";
import { AssigneeAvatar, AssigneeAvatarSmall } from "../dashboard/Common/AssigneeAvatar";

import {
  ArrowLeftOutlined,
  FormOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import "../../common/common.css";
import { Link } from "react-router-dom";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import { getEllipsisText } from "../../utils/Util";
const { Search } = Input;
interface ProjectData {
  assigneeIds: { label: any; value: any }[];
  assignee: any[];
}

const ProjectList = () => {
  const { isLoading, data: projectData, isFetching } = useGetProjects();
  const [searchProject, setsearchProject] = useState("");
  const [show, setShow] = useState("");
  const [data, setData] = useState<ProjectData | null>(null);
  // const [getData, setGetData] = useState<any>([])
  const handleSearchInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setsearchProject(event?.target?.value);
  };
  const filteredData = projectData?.result?.filter(
    (item: { name: string; alias: string; description: string }) =>
      item?.name?.toLowerCase()?.includes(searchProject?.toLowerCase()) ||
      item?.alias?.toLowerCase()?.includes(searchProject?.toLowerCase()) ||
      item?.description?.toLowerCase()?.includes(searchProject?.toLowerCase())
  );
  const onEdit = (values: { assignee: any[] }) => {
    const val = {
      ...values,
      assigneeIds: values?.assignee?.map((itm) => ({
  
        label: itm?.name,
        value: itm?.id,
      })),
    };
    setData(val);
    setShow("AddEditProject");
  };

  const columns: any = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "9%",
      render: (text: string, record: any, index: number) => 
        <div className="pl-4">{text}</div>
      ,
    },
    {
      sorter: (a: any, b: any) => a.Name - b.Name,
      title: "Name",
      dataIndex: "name",
      key: "name",
       width: "20%",
      render: (text: any, record: any) => <Typography.Text ellipsis     onClick={() => onEdit(record)} style={{cursor:'pointer'}}>{text}</Typography.Text>
    },
    {
      sorter: (a: any, b: any) => a.Name - b.Name,
      title: "Alias",
      dataIndex: "alias",
      key: "alias",
       width: "10%",
      render: (text: any) => text,
    },
    {
      title: "Assignees",
      dataIndex: "assignee",
      key: "assignee",
      width: "15%",
      render: (assignee: any[]) => (
        // <AssigneeAvatar maxCount={2} data={assignee} />
        <AssigneeAvatarSmall data={assignee}  />
      ),
    },
    {
      sorter: (a: any, b: any) => a.Name - b.Name,
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: "40%",
      render: (text: any, record: any) => (
        <Tooltip
          title={text}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {getEllipsisText(text, 150)}
        </Tooltip>
      ),
    },

    {
      title: "",
      dataIndex: "actions",
      key: "description",
      render: (text: any, record: any) => (
        <>
          <Row justify={"end"}>
            <FormOutlined
              className="capTask-edit-btn"
              onClick={() => onEdit(record)}
            />
          </Row>
        </>
      ),
    },
  ];
  // pagination code
  const [listParams, setListParams] = useState({
    start: 0,
    length: 10,
  });
  const [refresh,setRefresh] = useState<boolean>(false)
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
    
    //setListParams((x: any) => ({ ...x }));
  };
  const onChangeTable: TableProps<any>["onChange"] = (
    paging: any,
  ) => {
    
    if (paging.current !== undefined && paging.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh)
    }
  };
  const pageSizeOption = ["10", "15", "30", "50", "100", "200"];

  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "Project",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Button
              icon={<PlusOutlined />}
              className="capTask-btn btn-clr-primary btn-primary-md mb-1"
              size="small"
              onClick={() => {
                setShow("AddEditProject");
                setData(null);
              }}
            >
              Project
            </Button>

            <Search
              size="small"
              placeholder="Search..."
              allowClear
              onChange={handleSearchInputChange}
              style={{ width: 175 }}
              className="mb-1"
            />
          </Row>
        </Col>

        <Col span={24}>
          {isLoading ? (
            <TableSkeleton
              columns={columns}
              scroll={{ y: "75vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-table mt-3 project-table"
              size="small"
              onChange={onChangeTable}
              pagination={{
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              scroll={{ y: 500 }}
              columns={columns}
              // dataSource={filteredData}
              dataSource={filteredData.map((data:any, index:number) => ({
                serial:index+1,
                key: index,
                ...data,
              }))}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "40px", width: 140 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>

      {show === "AddEditProject" && (
        <AddEditProject onDismiss={() => setShow("")} data={data} />
      )}
    </>
  );
};

export default ProjectList;
