import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";
import { ChildTaskCreationMethod } from "../../utils/Util";

type TInitialState = {
    mode: ChildTaskCreationMethod;
};

const initialState: TInitialState = {
mode:ChildTaskCreationMethod.Standard,
}

const taskSlice = createSlice({
  name: "task",
  initialState,
  reducers: {
    setTaskMode: (state, action: PayloadAction<ChildTaskCreationMethod>) => {
      state.mode = action.payload;
    },
  },
});

export const { setTaskMode } = taskSlice.actions;
export const selectedTaskMethod = (state: RootState): ChildTaskCreationMethod => state.task.mode

export default taskSlice.reducer;
