import React from "react";
import { Tooltip, Avatar, message } from "antd";
import { getAvatarUrl } from "../../utils/Util";

interface AssigneeAvatarProps {
  assignee: any;
  index: number;
  size?: number;
  message?:string
}

const AssigneeAvatar: React.FC<AssigneeAvatarProps> = ({ assignee, index , size = 26,message }) => {
    // console.log("assignee",assignee);   

  return (
    <Tooltip
      title={
        <div className="text-center">
          <div>{assignee?.name|| assignee?.Name}</div>
          <div>{message}</div>
          <img
            src={getAvatarUrl?.(assignee)}
            alt=""
            style={{ maxWidth: "100px", maxHeight: "100px" }}
          />
          
        </div>
        
      }
      key={index}
    >
      <Avatar
        src={getAvatarUrl(assignee)}
        size={size}
      />
    </Tooltip>
  );
};

export default AssigneeAvatar;
