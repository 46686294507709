
import React from 'react';
import { Modal, Button } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

export interface ModalTemplate {
  open?:boolean,
  closeModal: () => void;
  onClose?: (params?: any) => void;
  width?: string;
  buttonText?: string;
  title: string;
  shouldShowFooter?: boolean;
  onSubmit?: () => void;
//    callback?: () => void;
  children: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ModalTemplate: React.FC<ModalTemplate> = ({
    open=true,
    closeModal,
    title,
    onSubmit = () => { },
    //   callback = () => {},
    children,
    width = "520px",
    shouldShowFooter = true,
    buttonText = "Save",
    onClose = () => { }
}: ModalTemplate) => {
    const handleClose = () => {
        onClose();
        closeModal();
        // callback();
    }
    return (
        <Modal 
        maskClosable={false}
            title={title}
            open={open}
            onCancel={handleClose}
            width={width}
            footer={shouldShowFooter ? (
                <div style={{ textAlign: 'right' }}>
                    <Button style={{ marginRight: "10px" }} onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button type="primary" onClick={onSubmit}>
                       Upload
                    </Button>
                </div>
            ) : null}
            closeIcon={<CloseOutlined style={{ color: '#bac2d0' }} />}
        >
            {children}
        </Modal>
    );
};