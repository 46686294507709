import { useCallback, useEffect, useState } from "react"
import BooksService from "../../../Services/Books"
import "./AllProject.css"
import AllProjectList from "./AllProjectList"
import AllAssigneeList from "./AllAssigneeList"
import AllMilestoneList from "./AllMilestoneList" 
import  HTTPSCalls  from "../../../Services/HTTPCalls"

const AllProject = ({ pendingTaskData, allMilestone }:any) => {

    const [getData, setGetData] = useState<any>([])
    const [projectList, setProjectList] = useState<any>([])
    const [isOpen, setIsOpen] = useState<any>(false);
    const [isOpen1, setIsOpen1] = useState<any>(false);
    const [isOpen2, setIsOpen2] = useState<any>(false);
    const openModal = useCallback(() => setIsOpen(true), []);
    const closeModal = useCallback(() => setIsOpen(false), []);

    const openModal1 = useCallback(() => setIsOpen1(true), []);
    const closeModal1 = useCallback(() => setIsOpen1(false), []);

    const openModal2 = useCallback(() => setIsOpen2(true), []);
    const closeModal2 = useCallback(() => setIsOpen2(false), []);

    const [assigneeList,setAssigneeList] = useState([])

    const getAllProject = async () => {
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.get)
        setProjectList(res.result)
    }

    const getAllAssignee = async () => {
        let res = await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAssigneeByTaskCount)
        setAssigneeList(res.result)
    }

    const getAllData = async () => {
        try {
            let res = await BooksService.getAllAdminData()
            setGetData(res.result)
        } catch (err) {

        }
    }

    useEffect(() => {
        getAllData()
        getAllProject()
        getAllAssignee()
    }, [])
   
    return (
        <>
            <div >
                <div>
                    <div className="allProjectrow" style={{}}>
                        <div onClick={() => {
                            openModal()
                        }} style={{ boxShadow: " 0px 0px 10px 0px rgba(146, 166, 195, 0.58)", cursor: "pointer" }} className="adminproject">
                            <div style={{ borderLeft: "5px solid #377dff", paddingLeft: "15px" }}>
                                <div>
                                    <h4 style={{ marginTop: "0px", marginBottom: "0px", color: "#364766" }}>Total Projects</h4>
                                </div>

                                <div>
                                    <span style={{ color: "#364766" }}>{getData.project}</span>
                                </div>
                            </div>
                        </div>

                        <div onClick={() => {
                            openModal1()
                        }} style={{ boxShadow: " 0px 0px 10px 0px rgba(146, 166, 195, 0.58)", cursor: "pointer" }} className="adminproject2">
                            <div style={{ borderLeft: "5px solid #377dff", paddingLeft: "15px" }}>
                                <div>
                                    <h4 style={{ marginTop: "0px", marginBottom: "0px", color: "#364766" }}>Total Assignee</h4>
                                </div>

                                <div>
                                    <span style={{ color: "#364766" }}>{getData.user}</span>
                                </div>
                            </div>
                        </div>

                        <div style={{ boxShadow: " 0px 0px 10px 0px rgba(146, 166, 195, 0.58)" }} className="adminproject2">
                            <div style={{ borderLeft: "5px solid #377dff", paddingLeft: "15px" }}>
                                <div>
                                    <h4 style={{ marginTop: "0px", marginBottom: "0px", color: "#364766" }}>Total Pending Tasks</h4>
                                </div>

                                <div>
                                    <span style={{ color: "#364766" }}>{pendingTaskData.length}</span>
                                </div>
                            </div>
                        </div>

                        <div onClick={() => {
                            openModal2()
                        }} style={{ boxShadow: " 0px 0px 10px 0px rgba(146, 166, 195, 0.58)", cursor: "pointer" }} className="adminproject2">
                            <div style={{ borderLeft: "5px solid #377dff", paddingLeft: "15px" }}>
                                <div>
                                    <h4 style={{ marginTop: "0px", marginBottom: "0px", color: "#364766" }}>Total Milestone</h4>
                                </div>

                                <div>
                                    <span style={{ color: "#364766" }}>{allMilestone.length}</span>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {
                <AllAssigneeList assigneeList={assigneeList} isOpen1={isOpen1} closeModal1={closeModal1} />
            }

            {
                <AllProjectList projectList={projectList} isOpen={isOpen} closeModal={closeModal} />
            }

            {
                <AllMilestoneList allMilestone={allMilestone} isOpen2={isOpen2} closeModal2={closeModal2} />
            }
        </>
    )
}

export default AllProject;