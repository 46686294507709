import { Draggable } from "@hello-pangea/dnd";
import {
  Avatar,
  Button,
  Card,
  Col,
  Row,
  Select,
  Tooltip,
  Typography,
} from "antd";
import { STATUS_ARRAY, statusArray } from "../../constants/commonConstants";
import "../../common/common.css";
import AssigneeAvatar from "../commoncomponents/AssigneeAvatar";
import { resetUserData } from "../../store/slices/user-slice";
import { useNavigate } from "react-router-dom";

export const DraggableItemGridView = ({
  task,
  index,
  size,
  page,
  showLoading,
  sourceDroppableId,
  onSelectDropDown,
  onClick: propsOnClick,
}: any) => {
  function substringmethod(data: string) {
    if (data?.length > 6) {
      return data.substring(0, 6) + "...";
    } else {
      return data;
    }
  }
  const priorityIcon = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(2).svg"}
              alt=""
            />
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(3).svg"}
              alt=""
            />
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            <img
              style={{ height: "11px" }}
              src={process.env.PUBLIC_URL + "/icons(1).svg"}
              alt=""
            />
          </span>
        );
      default:
        return null;
    }
  };
  const navigate = useNavigate();
  const handleChange = (v: any) => {
    // console.log(STATUS_ARRAY[v?.value],'ash');

    onSelectDropDown({
      destinationDroppableId: STATUS_ARRAY[v?.value],
      sourceIndex: index,
      sourceDroppableId:
        page === "backlog" ? sourceDroppableId : STATUS_ARRAY[task?.tStatus],
      task: task,
    });
    resetUserData();
  };

  return (
    <Draggable draggableId={String(task?.id)} index={index}>
      {(provided, snapshot) => (
        <div
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided?.innerRef}
          style={{
            ...provided?.draggableProps?.style,
          }}
        >
          <Card
            style={{
              borderRadius: "3px",
              border: "none",
              backgroundColor: "white",
              cursor: "grab",
            }}
            className="itemshover mt-2 mb-2"
            hoverable
          >
            <Row>
              <Col lg={10} className="pr-1">
                <Tooltip title={task.title}>
                  <Typography.Text
                    ellipsis
                    onClick={() => {
                      showLoading?.(task?.uKey);
                      propsOnClick(task);
                    }}
                  >
                    {substringmethod(task?.title)}
                  </Typography.Text>
                </Tooltip>
              </Col>

              <Col lg={12} span={10} md={17}>
                <Select
                  labelInValue
                  className="taskbord"
                  size="small"
                  style={{ width: "5vw" }}
                  options={statusArray}
                  value={task?.tStatus}
                  showSearch
                  filterOption={(input, option: any) =>
                    option?.label?.toLowerCase()?.includes(input?.toLowerCase())
                  }
                  onChange={(e) => handleChange(e)}
                />
              </Col>
            </Row>
            <Row className="pt-2" justify={"space-between"}>
              <Col lg={2} xl={2} className="mr-1">
                <Typography.Text  onClick={() => {
                      showLoading?.(task?.uKey);
                      propsOnClick(task);
                    }}>
                  {priorityIcon(task?.priority)}
                </Typography.Text>
              </Col>
              <Col
                lg={10}
                xl={9}
                md={7}
                onClick={() => navigate("/task/" + task?.id)}
              >
                <Button
                  size="small"
                  className="resume-btn-sm"
                  style={{
                    backgroundColor: "rgb(223, 246, 221)",
                    width: "4vw",
                    border: "none",
                  }}
                >
                  <Tooltip title= {task?.uKey}>

                 <Typography.Text style={{    color: "rgb(16, 124, 16)",}} ellipsis> {task?.uKey}</Typography.Text>
                  </Tooltip>
                </Button>
              </Col>
              <Col lg={12} md={3} style={{ textAlign: "end" }}>
                <Avatar.Group
                  size={26}
                  max={{ count: 2 }}
                  // maxPopoverTrigger="click"
                  // maxPopoverPlacement="bottom"
                  className="cursorPointer"
                >
                  {task?.assignee?.map((assignee: any, index: any) => (
                    <>
                      <AssigneeAvatar
                        size={size}
                        key={index}
                        assignee={assignee}
                        index={index}
                      />
                    </>
                  ))}
                </Avatar.Group>
              </Col>
            </Row>
          </Card>
        </div>
      )}
    </Draggable>
  );
};

export default DraggableItemGridView;
