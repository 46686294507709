import React, { useEffect, useState } from "react";
import {
  Layout,
  Breadcrumb,
  Row,
  Col,
  Spin,
  message,
} from "antd";
import HTTPSCalls from "../Services/HTTPCalls";
import "../common/Scrollbar.css";
import "../common/Board.css";
import ActiviyLogs from "./dashboard/ActiviyLogs/ActiviyLogs";
import Toothbar from "../components/commoncomponents/Toothbar";
import { useDispatch, useSelector } from "react-redux";
import { projectData } from "../store/slices/project-slice";
import { STATUS } from "../constants/commonConstants";
import BooksService from "../Services/Books";
import DragableBoard from "../components/dragableBoard/DragableBoard";
import SidebarDrawerbacklog from "../components/dragableBoard/SidebarDrawer";
// import { userData } from "../store/slices/user-slice";
import {
  activeRefreshState,
  backlogRefreshState,
  refreshBacklog,
  refreshTaskDrawer,
} from "../store/slices/refresh-slice";
import { addSprintChangeLog, addStatusChangeLog } from "../utils/Util";
import { cloneDeep } from "lodash";
import { Link } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import AddEditTask from "../common/AddEditTask";
import { queryClient } from "..";
// import Loader from "../components/commoncomponents/Loader";
const splittedpath = window.location.pathname.split("/");
const projecturlid = splittedpath[splittedpath.length - 2];
const projecturlname = splittedpath[splittedpath.length - 3];

export enum UserType {
  All,
  Assignee,
  AssignedBy,
}

const Backlog: React.FC = () => {
  const [selectedAvatarId, setSelectedAvatarId] = useState<number | null>(null);
  const project = useSelector(projectData);
  const [taskSearchFilter, setTaskSearchFilter] = useState<{
    projectId: string;
    searchText: string | undefined | null;
    date:
      | {
          from: any;
          to: any;
        }
      | undefined
      | null;
    priority: number[] | undefined | null;
    searchIn: "" | "Assignee" | "AssignedBy" | undefined | null;
    teamMemberIds: string[] | undefined | null;
    sprintIds: string[] | undefined | null;
  }>({
    projectId: project?.id,
    date: undefined,
    priority: undefined,
    searchIn: undefined,
    sprintIds: undefined,
    teamMemberIds: undefined,
    searchText: undefined,
  });
  const [selectedRecord, setSelectedRecord] = useState<any>(undefined);

  // const user = useSelector(userData);
  const backlogRefresh = useSelector(backlogRefreshState);
  const refreshActive = useSelector(activeRefreshState);
  const dispatch = useDispatch();
  const [taskData, setTaskData] = useState<any>();
  const [projectname, setProjectName] = useState(project?.name || "");
  const [projectid, setProjectId] = useState(() => {
    return projecturlid || project?.id || "";
  });
  // const [refresh, setRefresh] = useState(0);
  // const [projectimage, setProjectImage] = useState("");
  const [projects, setProjects] = useState<any>(null);
  const [sprintData, setSprintData] = useState<any>(null);
  const [isloading, setIsLoading] = useState(false);
  const [titleimg, setTitleImg] = useState("");
  const [randomcolor, setRandomColor] = useState("");
  const [width, setWidth] = useState(window.innerWidth);
  const [userfilterid, setUserFilterId] = useState("");
  const [status, setstatus] = useState(-1);
  const [defaultUser, setDefaultUser] = useState<UserType>(UserType.All);
  // const [cardWidth, setCardWidth] = useState(window.innerWidth);
  // const [viewMode, setViewMode] = useState("list");
  const [fromdate, setFromdate] = useState("");
  const [priority, setPriority] = useState("");
  const [todate, setTodate] = useState("");
  const [assigne, setAssigne] = useState();
  const [localproject, setlocalporject] = useState([]);
  const [statusFilter, setstatusFilter] = useState("0,1,2,3,4,5,7");
  const [selectedAssignee, setSelectedAssignee] = useState<any>();
  const [refreshData, setRefreshData] = useState<boolean>(false);
  const [searchTaskData, setSearchTaskData] = useState<any>();
  // const [assignedby, setAssignedby] = useState("");

  useEffect(() => {
    const handleResize = () => {
      // setCardWidth(window.innerWidth);
      setWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    fetchSprint();
    return () => window.removeEventListener("resize", handleResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    fetchSprint();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    project,
    defaultUser,
    fromdate,
    todate,
    priority,
    // selectedAssignee,
    backlogRefresh,
  ]);
  const fetchSprint = async (assigneeid?: any, assignee?: any) => {
    setIsLoading(true);
    try {
      // settaskdata({})
      if (project?.id !== "") {
        await BooksService.getSprint(project?.id).then(async (res) => {
          if (res?.status) {
            let result2: any = {};
            // console.log("res.result", res.result);

            setSprintData(res?.result);
            res?.result.forEach((value: any) => {
              result2[value?.id] = [];
            });
            result2["BACKLOG"] = [];
            const projectId = project?.id || projectid;
            const response = await HTTPSCalls.GET(
              HTTPSCalls.ENDPOINTS.task.getAllBySprint + "?",
              {
                fromDate: fromdate,
                todate: todate,
                priority: priority,
                sprintId: projectId,
                statusFilter: statusFilter,
                status: status,
                start: 0,
                length: 15000,
                projectid: projectId,
                assigneeId: assignee,
                isDone: 1,
                Utype: defaultUser,
              }
            );
            response?.result?.forEach((value: any) => {
              if (Array.isArray(value?.sprint) && value?.sprint[0]?.id) {
                result2[value?.sprint[0]?.id] = value?.tasks;
              } else {
                result2["BACKLOG"] = [
                  ...(result2["BACKLOG"] || []),
                  ...(value?.tasks || []),
                ];
              }
            });
            setSearchTaskData(result2);
            setTaskData(result2);
          }
        });
      }
    } catch (e) {
      console.log("error", e);
    } finally {
      setIsLoading(false);
    }
  };

  const onDragEnd = async (result: any) => {
    try {
      setIsLoading(true);
      // console.log("result", result);
      const { source, destination } = result;
      if (!destination) return;

      const taskCategories = Object.keys(taskData);
      const sourceIndex = taskCategories.indexOf(source?.droppableId);
      const destinationIndex = taskCategories.indexOf(destination?.droppableId);

      result.task = cloneDeep(taskData[source.droppableId][source.index]);
      // if destination is BACKLOG, DONE, DUPLICATE
      if (destinationIndex === -1) {
        const columnData = [...taskData[source.droppableId]];
        columnData.splice(source.index, 1);

        const tasks = {
          ...taskData,
          [source.droppableId]: columnData,
        };
        setTaskData(tasks);
        // debugger;
        destination.droppableId !== "BACKLOG" &&
          updateStatus(
            taskData[source.droppableId][source.index]["id"],
            STATUS[destination.droppableId],
            result?.task
          );
      }

      if (
        sourceIndex < 0 ||
        sourceIndex >= taskCategories?.length ||
        destinationIndex < 0 ||
        destinationIndex >= taskCategories?.length
      ) {
        return; // Invalid indices
      }

      const taskId = result?.draggableId;
      const taskDestinationStatus = destination?.droppableId;
      // result.task = taskData[source.droppableId][source?.index];
      await HandleStatusDropdown(
        result,
        taskId,
        sourceIndex,
        destinationIndex,
        taskDestinationStatus
      );
    } catch (error) {
      console.log("error", error);
    } finally {
      setIsLoading(false);
    }
  };
  const reorder = (list: any, startIndex: any, endIndex: any) => {
    const result: any = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    if (endIndex > 0) {
      result[endIndex].orderno = String(
        Number(result[endIndex - 1]?.orderno) + 1
      );
    }
    return result;
  };

  const move = (
    source: any,
    destination: any,
    droppableSource: any,
    droppableDestination: any
  ) => {
    const sourceClone: any = Array.from(source);
    const destClone: any = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource?.index, 1);
    removed.tStatus =
      droppableDestination?.droppableId === "BACKLOG"
        ? removed?.tStatus
        : removed?.tStatus === STATUS.BACKLOG
        ? STATUS?.TODO
        : removed?.tStatus
   
         // STATUS[droppableDestination.droppableId];
         
    destClone?.splice(droppableDestination?.index, 0, removed);

    if (droppableDestination?.index > 0) {
      destClone[droppableDestination.index].orderno = String(
        Number(destClone[droppableDestination.index - 1].orderno) + 1
      );
    }
    const result: any = {};

    destClone.map(
      (value: any) => (value.boardno = droppableDestination.droppableId)
    );
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;
    return result;
  };

  const HandleStatusDropdown = async (
    result: any,
    taskId: string,
    sourceIndex: number,
    destinationIndex: number,
    taskDestinationStatus: any
  ) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }
    const stateArrValues: any = Object.values(taskData);
    const stateArrKeys = Object.keys(taskData);
    if (sourceIndex === destinationIndex) {
      const items = reorder(
        stateArrValues[sourceIndex],
        source.index,
        destination.index
      );
      stateArrValues[sourceIndex] = items;
      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
      }));
    } else {
      const moveResult: any = move(
        stateArrValues[sourceIndex],
        stateArrValues[destinationIndex],
        source,
        destination
      );

      stateArrValues[sourceIndex] = moveResult[stateArrKeys[sourceIndex]];
      stateArrValues[destinationIndex] =
        moveResult[stateArrKeys[destinationIndex]];

      setTaskData((p: any) => ({
        ...p,
        [stateArrKeys[sourceIndex]]: stateArrValues[sourceIndex],
        [stateArrKeys[destinationIndex]]: stateArrValues[destinationIndex],
      }));

      const res = await updateTaskSprint({
        taskId: taskId,
        sprintId:
          taskDestinationStatus === "BACKLOG" ? "" : taskDestinationStatus,
        task: result?.task,
      });
      if (res?.status) {
        if (taskDestinationStatus !== "BACKLOG") {
          if (result?.task?.tStatus === STATUS.BACKLOG) {
            
            const status =
              result?.task?.tStatus === STATUS.BACKLOG
                ? STATUS?.TODO
                : result?.task?.tStatus;
            updateStatus(taskId, status, result?.task);
          }
          
        }
      }
    }
  };

  const updateStatus = async (
    taskId: string,
    status: number | string,
    task: any
  ) => {
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateStatus + "?",
        {},
        {
          tid: taskId,
          tstatus: status,
        }
      );
      message.success({
        content: (
          <span>
            Task <span style={{ color: "#f1807e" }}>Status</span> updated
            successfully.
          </span>
        ),
        duration: 2, // You can adjust the duration if needed
      });
      
      addStatusChangeLog(task, status, task?.tStatus);
      return res;
    } catch (error) {
      console.log("error", error);
      message.error({
        content: "Failed to update task Status.",
        duration: 2, // You can adjust the duration if needed
      });
      
    } finally {
      dispatch(refreshTaskDrawer());
      dispatch(refreshBacklog());
    }
  };

  const updateTaskSprint = async ({
    taskId,
    sprintId,
    task,
  }: {
    taskId: string;
    sprintId: any;
    task: any;
  }) => {
    try {
      const response = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateTaskSprint + "?",
        {
          id: taskId,
          sprintId: sprintId,
        }
      );

      message.success({
        content: (
          <span>
            Task <span style={{ color: "#276e9d" }}>Sprint</span> Assignee
            successfully.
          </span>
        ),
        duration: 2, // You can adjust the duration if needed
      });
      // console.log("sprintId", sprintId, "task", task);

      const toSprintName = sprintId
        ? sprintData.find((s: any) => s?.id === sprintId)?.name
        : "Backlog";

      const fromSprintName =
        task?.sprint?.length > 0 ? task?.sprint[0]?.name : "Backlog";

      addSprintChangeLog(task, toSprintName, fromSprintName);
      return response;
    } catch (e) {
      console.error("error", e);

      message.error({
        content: "Failed to update task Sprint.",
        duration: 2, // You can adjust the duration if needed
      });
    } finally {
      dispatch(refreshTaskDrawer());
      dispatch(refreshBacklog());
    }
  };

  const onSelectDropDown = ({
    destinationDroppableId,
    sourceIndex,
    sourceDroppableId,
    task,
  }: {
    destinationDroppableId: keyof typeof STATUS;
    sourceIndex: number;
    sourceDroppableId: string;
    task: any;
  }) => {
    
    const result = {
      destination: {
        index: 0,
        droppableId: destinationDroppableId,
      },
      source: {
        index: sourceIndex,
        droppableId: sourceDroppableId,
      },
      draggableId: task?.id,
      task,
    };
    // console.log("result", result);
    if (
      destinationDroppableId === "BACKLOG" ||
      destinationDroppableId === "DONE" ||
      destinationDroppableId === "DUPLICATE"
    ) {
      onDragEnd(result);
    } else {
      // debugger;
      
      updateStatus(
        taskData[sourceDroppableId][sourceIndex]["id"],
        STATUS[destinationDroppableId],
        result?.task
      );
    }
  };

  // handleAvatarClick Api
  // const fetchTasks = async (page: any, assigneId?: any) => {
  //   setIsLoading(false);
  //   try {
  //     if (projectid != "") {
  //       setIsLoading(true);
  //       let response = await HTTPSCalls.GET(
  //         HTTPSCalls.ENDPOINTS.task.getAllByStatus + "?",
  //         {
  //           statusFilter: statusFilter,
  //           status: status,
  //           start: 0,
  //           length: 15000,
  //           projectid: projectid,
  //           assigneeId: assigneId ? assigneId : selectedAssignee,
  //           isDone: 1,
  //           Utype: defaultUser,
  //         }
  //       );
  //       if (response.result) {
  //         setProjects(response.result);
  //         setlocalporject(response.result);
  //       }
  //     }
  //   } catch (e) {
  //     console.log("error", e);
  //   } finally {
  //     setIsLoading(false);
  //   }
  // };

  const handleAvatarClick = (assignee: any) => {
    fetchSprint(null, assignee);
    setSelectedAssignee(assignee);
    setSelectedAvatarId(assignee);
    // console.log("HT:", assignee.id);
  };

  const breadcrumbItems = [
    {
      title: (
        <Link to={"/dashboard"} className="BreadcrumbLink">
          Home
        </Link>
      ),
    },
    {
      title: "Backlog",
      // href: "/backlog",
    },
  ];

  // Sidebar Drawer State
  const [open, setOpen] = React.useState<boolean>(false);
  // const [loading, setLoading] = React.useState<boolean>(true);
  const [selectid, setSelectedId] = React.useState<string | null>();
  // const [loadData, setLoadData] = React.useState<boolean>(false);
  const showLoading = (id: string) => {
    setOpen(true);
    setSelectedId(id);
  };
  return (
    <>
      <Spin
        spinning={isloading}
        indicator={<LoadingOutlined spin />}
        size="default"
      >
        <Row justify={"end"} className="mb-2">
          <Col style={{ fontSize: "12px", textAlign: "center" }}>
            <Breadcrumb items={breadcrumbItems} />
          </Col>
        </Row>

        <Layout style={{ backgroundColor: "white" }}>
          <Row style={{ backgroundColor: "white" }}>
            <Col
              
              md={24}
              lg={16}
              xl={18}
             xxl={19}
              style={{ marginTop: "8px", height: "85vh" }}
            >
              <div>
                {taskData && (
                  <>
                    <div
                      className="ToothierHide"
                      style={{ paddingLeft: "26px" }}
                    >
                      <Toothbar
                        page="backlog"
                        setAssigne={setAssigne}
                        setPriority={setPriority}
                        setTodate={setTodate}
                        setstatus={setstatus}
                        // fetchTasks={fetchTasks}
                        setFromdate={setFromdate}
                        assigne={assigne}
                        setDefaultUser={setDefaultUser}
                        defaultUser={defaultUser}
                        setIsLoading={setIsLoading}
                        setSelectedAssignee={setSelectedAssignee}
                        isLoading={isloading}
                        projecturlname={projecturlname}
                        setTitleImg={setTitleImg}
                        userfilterid={userfilterid}
                        setUserFilterId={setUserFilterId}
                        handleAvatarClick={handleAvatarClick}
                        randomcolor={randomcolor}
                        setRandomColor={setRandomColor}
                        titleimg={titleimg}
                        setProjectName={setProjectName}
                        setProjectId={setProjectId}
                        projects={projects}
                        projectid={projectid}
                        projectname={projectname}
                        selectedAvatarId={selectedAvatarId}
                        selectedAssignee={selectedAssignee}
                        setProjects={setProjects}
                        taskData={taskData}
                        setTaskData={setTaskData}
                        localproject={localproject}
                        setstatusFilter={setstatusFilter}
                        setlocalporject={setlocalporject}
                        setTaskSearchFilter={setTaskSearchFilter}
                        setRefreshData={setRefreshData}
                        setSearchTaskData={setSearchTaskData}
                        searchTaskData={searchTaskData}
                        setSelectedAvatarId={setSelectedAvatarId}
                      />
                    </div>
                    <DragableBoard
                      page="backlog"
                      tasks={taskData}
                      viewMode={"list"}
                      onDragEnd={onDragEnd}
                      showLoading={showLoading}
                      onSelectDropDown={onSelectDropDown}
                      sprintData={sprintData}
                      handleAvatarClick={handleAvatarClick}
                      onClick={(record: any) => {
                        queryClient.invalidateQueries({
                          queryKey: ["taskGetById"],
                        });
                        
                        setSelectedRecord(record);
                      }}
                    />
                  </>
                )}
              </div>
            </Col>

            <Col
            
              md={24}
              lg={8}
              xl={6}
             xxl={5}
              style={{ marginTop: "8px", height: "80vh" }}
            >
              <ActiviyLogs y={"75vh"} params={{ projectid: project?.id }} />
            </Col>
          </Row>
          {open && (
            <AddEditTask

              id={selectedRecord?.id}
              onDismiss={(rec: boolean) => {
                // setSelectedId("")
                setOpen(!open);
              }}
            />
          )}
        </Layout>
      </Spin>
    </>
  );
};

export default Backlog;
