import {
  Modal,
  Row,
  Col,
  Input,
  Form,
  DatePicker,
  message,
  Flex,
  Button,
  Drawer,
} from "antd";
import React, { useEffect, useState } from "react";
// import ImageUploader from '../AntDashboard/Common/ImageUploader'
// import { useGetProjects } from '../../Services/RQDashboardService'
import { useSprint } from "../../Services/RQMastersService";
import TextArea from "antd/es/input/TextArea";
import { CloseOutlined } from "@ant-design/icons";
const { RangePicker } = DatePicker;
const AddEditSprint = (props: any) => {
  const { mutateAsync: sprintMutate, isPending } = useSprint();
  const [messageApi, contextHolder] = message.useMessage();
  const [loading, setLoading] = useState(false);
  const drawerSprint =  props?.data?.data?.id ? "Edit Sprint" : "Add Sprint";
  //  console.log(props.data, "data");
  const [open, setOpen] = useState(true);
  const [form] = Form.useForm();
  useEffect(() => {
    form.setFieldsValue({ ...props?.data, ...props?.data?.data });
  }, []);
  const handleSubmit = async () => {
    try {
      setLoading(true);
      const values = await form.validateFields();
      
      const val: any = {
        startDate: values?.fromTo[0],
        endDate: values?.fromTo[1],
        name: values?.name?.trim(),
        projectId: props?.data?.projectId,
        sprintGoal: values?.sprintGoal,
        id: props?.data?.data?.id,
      };
      const result = await sprintMutate(val);

      if (result?.status) {
        message.success("Issue submitted successfully!");
        onCancel();
      } else {
        messageApi.error(result?.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error occurred:", error);
    } finally {
      setLoading(false);
    }
  };

  const onCancel = () => {
    form.resetFields();
    setOpen(() => !open);
    setTimeout(() => {
      props?.onDismiss();
    }, 300);
  };
  const values = Form.useWatch([], form);
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
      setLoading(false)
  }, [form, values]);
  return (
    <>
      {contextHolder}
      <Drawer
  
        title={drawerSprint}
        extra={
          <>
            <CloseOutlined className="cursor" onClick={onCancel} />
          </>
        }
        closeIcon={false}
        open={open}
        footer={
          <>
            <Row justify={"end"}>
              <Button className="capTask-btn-light btn-m-sm" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                className="btn-m-sm"
                type="primary"
                onClick={handleSubmit}
                disabled={!submittable}
                loading={loading}
              >
                Save
              </Button>
            </Row>
          </>
        }
        width={500}
      >
        <Form
          form={form}
          labelAlign="left"
          layout="horizontal"
          labelCol={{ span: 5 }}
          requiredMark={false}
          initialValues={{ orderNo: 0 }}
          onFinish={() => message.success("Submit success!")}
          onFinishFailed={() => message.error("Submit failed!")}
          className="DrawerPadding"
        >
          <Form.Item label="Name" name={"name"}>
            <Input
              disabled
              placeholder="Name"
              variant="borderless"
              className="borderLessInput"
            />
          </Form.Item>

          <Form.Item
            label="Date"
            name="fromTo"
            rules={[{ required: true, message: "This field is required." }]}
          >
            <RangePicker
              format="DD/MM/YYYY"
              variant="borderless"
              className="borderLessInput"
            />
          </Form.Item>

          <Form.Item label="Goal" name={"sprintGoal"}>
            <TextArea
              variant="borderless"
              className="borderLessInput"
              placeholder="Goal"
            />
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};

export default AddEditSprint;
