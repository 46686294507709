// Auth related services

import { useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { RootState, store } from "../store";
import { selectUserId } from "../store/slices/user-slice";

// const userId = currentState?.user?.user?.id;
// Get Project List
export const getProjectsList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.get + "?", {
    start: 0,
    length: 3000,
    uid: payload,
  });

export const useGetProjects = () => {
  const userId: string = selectUserId(store.getState());
  return useQuery({
    queryKey: ["projectList", userId],
    queryFn: () => getProjectsList(userId),
  });
};
// Get User List
export const getUserList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", {
    start: 0,
    length: 3000,
    uid: payload,
  });

export const useGetUser = () => {
  const userId: string = selectUserId(store.getState());
  return useQuery({
    queryKey: ["userList", userId],
    queryFn: () => getUserList(userId),
  });
};

// Get Pending Task
export const getPendingList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getAllPending + "?", payload);

export const useGetPendingTasks = (payload: any, loginId: any) => {
  return useQuery({
    queryKey: ["pendingTask", loginId],
    queryFn: () => getPendingList(payload),
  });
};

// Get Logs List
export const getLogsList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.log.get + "?", payload);

export const useGetLogsList = (
  payload: any,
  loginId: any = null,
  projectId: any = null
) => {
  return useQuery({
    queryKey: ["logsList", loginId, projectId],
    queryFn: () => getLogsList(payload),
  });
};
export const useGetLogsListDashboard = (
  payload: any,
  loginId: any = null,
) => {
  return useQuery({
    queryKey: ["logsListDashboard", loginId],
    queryFn: () => getLogsList(payload),
  });
};

//Get Task Board List
export const getTaskBoardList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getAll + "?", payload);


export const useGetTaskBoardList = (payload: any, loginId:any) => {
  return useQuery({
    queryKey: ["taskBoardList", loginId],
    queryFn: () => getTaskBoardList(payload),
  });
};

//Get Assignee List

export const getAllAssigneeList = async (payload: any) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.user.getAll + "?", payload);

export const useGetAllAssigneeList = (payload: any) => {
  return useQuery({
    queryKey: ["assigneeList"],
    queryFn: () => getAllAssigneeList(payload),
  });
};


// export const DashboardProjects = async (payload: any) =>
//   await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.projectRoute.getAll + "?", payload);
// export const useGetProjectByUserList = (payload: any) => {
//   return useQuery({
//     queryKey: ["dashboardProjects"],
//     queryFn: () => DashboardProjects(payload),
//   });
// };
