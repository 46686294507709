import React, { useMemo, useState } from "react";
import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import IssueSearchFilter from "../../../components/commoncomponents/IssueSearchFilter";
import IssuesTable from "../../IssuesTable/IssuesTable";
import { useReportTasks } from "../../../Services/RQReportsService";
import { projectData } from "../../../store/slices/project-slice";
import { useSelector } from "react-redux";
import { userData } from "../../../store/slices/user-slice";
import { getCsvDownload } from "../../../Services/RQTaskService";
export enum userType {
  All,
  Assignee,
  AssignedBy,
  Reporter,
}

interface IUserFilter {
  userType: userType;
  id: string;
}

interface IFormData {
  project: { label: string; value: string }[];
  SearchText: string;
  status: number[];
  priority: number;
  userFilter: IUserFilter;
}
const IssuesReport = () => {
  const user = useSelector(userData);
  const project = useSelector(projectData);
  const [searchFilter, setSearchFilter] = useState<IFormData>({
    project: [
      {
        label: project?.name,
        value: project?.id,
      },
    ], // Initial project selection
    SearchText: "",
    status: [0, 1, 2, 3, 4, 5, 7],
    priority: -1,
    userFilter: { userType: userType.All, id: user?.id },
  });
  // Check if there's at least one project before making the API call
  const { data: reportData, isPending: isReportPending } = useReportTasks(
    searchFilter?.project?.length > 0
      ? {
          ...searchFilter,
          project: searchFilter?.project?.map((item: any) => item?.value),
        }
      : null
  );
  const reportList = useMemo(() => {
    return reportData?.result;
  }, [reportData]);

  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/reports"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/reports"} className="BreadcrumbLink">
                    Reports
                  </Link>
                ),
              },
              {
                title: "Issues",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white">
        <Col span={24} className="ml-5">
          <IssueSearchFilter
           onDownload={()=>{
            getCsvDownload({...searchFilter,
              project:searchFilter?.project?.map((item: any) => item?.value),
              
            })
            
           }}
            onChange={(val: any) => {
           
            
              if (!!val) setSearchFilter({ ...val,
                userFilter:{
                  id:val?.userFilter?.value,
                  userType:val?.userType?.value??0
                }
               });
            }}
          />
        </Col>
        <Col span={24}>
          <IssuesTable loading={isReportPending} reportList={reportList} />
        </Col>
      </Row>
    </>
  );
};

export default IssuesReport;
