import { ArrowLeftOutlined, RightOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Col, Row, Typography } from 'antd';
import React from 'react'
import { Link } from 'react-router-dom';
import { AppRoutesPath } from '../../Routes/AppRoutesPath';

const Reports = () => {
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 20 }}
      >
        <Col>
          <Link to={"/dashboard"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },

              {
                title: "Reports",
              },
            ]}
          />
        </Col>
      </Row>
      <div className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Row className="pt-4">
          <Col span={8}>
            <Typography.Title style={{ color: "#7b8ca8", fontSize: "16px" }}>
              Task Reports
            </Typography.Title>
          </Col>
        </Row>
        <Row style={{ display: "flex" }}>
          <Col span={24}>
            <RightOutlined style={{ color: "rgb(119, 119, 119)" }} />
            <Link to={AppRoutesPath?.reports?.issues}>
              <Button
                type="link"
                style={{
                  backgroundColor: "transparent",
                  fontSize: "15px",
                  transition: "color 0.3s",
                }}
              >
                Issues Report
              </Button>
            </Link>
          </Col>
          <RightOutlined style={{ color: "rgb(119, 119, 119)" }} />
          <Link to={AppRoutesPath?.reports?.inHand}>
            <Button
              type="link"
              style={{
                backgroundColor: "transparent",
                fontSize: "15px",
                transition: "color 0.3s",
              }}
            >
              inHand Report
            </Button>
          </Link>
        </Row>
      </div>
    </>
  );
};

export default Reports;
