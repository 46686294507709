import { useEffect, useMemo, useState } from "react";
import { Modal, Button, Select, Form, Row, message } from "antd";
import { AddEditApis } from "../../Services/RQTaskService";
import React from "react";
import { useProjectuser } from "../../Services/RQMastersService";
import { projectData } from "../../store/slices/project-slice";
import { useSelector } from "react-redux";
const Adduser = ({
  isOpen2,
  closeModal2,
  projectassigneearray,
  props,
}: any) => {
  const { useUser } = AddEditApis;
  const project = useSelector(projectData);
  const [projectdata, setprojectdata] = useState<any>({});
  const [form] = Form.useForm();
  const { mutateAsync: addUserproject } = useProjectuser();
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [searchFilter, setSearchFilter] = useState<{
    user: { search: undefined };
  }>({ user: { search: undefined } });
  const { data: userDataList, isPending: isUserPending } = useUser(
    searchFilter?.user
  );
  const handleError = (error: any) => {
    // Handle error
  };

  const reporterOptions = useMemo(() => {
    return userDataList?.result?.items?.map((itm: any) => itm) || [];
  }, [userDataList]);
  useEffect(() => {
    form?.setFieldsValue(props?.data);
  }, []);
  const handleSubmit = () => {
    form
      .validateFields()
      .then(async (values: any) => {
        setLoading(true);
        //  console.log(values,"=Valuee");
        let val = {
          ...values,
          id: project?.id,
          name: projectdata?.name,
          assigneeIds: values.assignees,
          type: 1,
        };
        // console.log("val", val);
        let res = await addUserproject(val);
        if (res?.message) {
          console.error("Error =", res?.message);
          message.error("Error-> " + res?.message);
          setLoading(false);
        } else {
          message.success("Project Added Successfully");
          form.resetFields();
          closeModal2();
        }
      })
      .catch(handleError);
  };
  useEffect(() => {
    if (projectassigneearray?.length > 0) {
      form.setFieldValue("assigneeIds", projectassigneearray);
    } else {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
        .finally(() => setLoading(false));
    }
  }, [projectassigneearray, form]);
  useEffect(() => {
    if (submittable) {
      console.log("Submittable is true");
    }
  }, [submittable]);

  return (
    <Modal
      open={isOpen2}
      title="Add Assignee"
      onCancel={closeModal2}
      footer={
        <>
          <Row justify={"end"}>
            <Button
              className="capTask-btn-light btn-m-sm"
              onClick={closeModal2}
            >
              Cancel
            </Button>
            <Button
              className="btn-m-sm"
              type="primary"
              onClick={handleSubmit}
              loading={loading}
              disabled={!submittable}
            >
              Add
            </Button>
          </Row>
        </>
      }
    >
      <Form
        form={form}
        labelAlign="left"
        layout="horizontal"
        labelCol={{ span: 6 }}
        requiredMark={false}
        initialValues={{ orderNo: 0 }}
        className="DrawerPadding"
      >
        <Form.Item
          name="assigneeIds"
          rules={[{ required: true, message: "Please select an assignee" }]}
        >
          <Select
            maxTagCount="responsive"
            labelInValue
            mode="multiple"
            variant="borderless"
            className="borderLessInput w100"
            placeholder="Choose reporter"
            allowClear
            options={reporterOptions}
            loading={isUserPending}
            searchValue={searchFilter?.user?.search}
            showSearch
            onSearch={(value) => {
              setSearchFilter((pre: any) => ({
                ...pre,
                user: {
                  search: value,
                },
              }));
            }}
            optionFilterProp="label"
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Adduser;
