import { useQuery } from "@tanstack/react-query"
import HTTPSCalls from "./HTTPCalls";

export const useTaskListByBoard = (payload:any = undefined)=>{
     return useQuery({ 
      enabled:!!payload?.projectId,
      queryKey: ["useTaskListByBoard",payload],
      queryFn: () => getTaskListByBoard(payload),
    })
  }

  const getTaskListByBoard = async (payload: any) =>
    await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.board.taskListByBoard, {
      ...payload,
    });