import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";

type Project = {
  id: string;
  name: string;
};

type TInitialState = {
  project: Project;
  loading: boolean;
  boardViewMode?: string;
};

const initialState: TInitialState = {
  project: {
    id: "",
    name: "",
  },
  boardViewMode: "grid",
  loading: false,
};

const projectSlice = createSlice({
  name: "project",
  initialState,
  reducers: {
    setProjectData: (state, action: PayloadAction<Project>) => {
      state.project = action.payload;
    },
    resetProjectData: (state) => {
      state.project = initialState.project;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setBoardViewMode: (state, action: PayloadAction<string>) => {
      state.boardViewMode = action.payload;
    },
  },
});

export const { setProjectData, resetProjectData, setLoading, setBoardViewMode } =
  projectSlice.actions;

export const projectData = (state: RootState): Project => state?.project?.project;
export const loading = (state: RootState): boolean => state?.project?.loading;
export const boardViewMode = (state: RootState): string | undefined =>
  state.project.boardViewMode;

export default projectSlice.reducer;
