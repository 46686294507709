import React, { useCallback, useMemo, useState, useEffect } from "react";
import { useGetPendingTasks } from "../../../Services/RQDashboardService";
import moment from "moment";
import { Avatar, Button, Col, ConfigProvider, Modal, Row, Table, Tooltip, Typography } from "antd";
import { AssigneeAvatarSmall } from "../Common/AssigneeAvatar";
import BooksService from "../../../Services/Books";
import {
  getAvatarUrl,
  getEllipsisText,
  initialsgenerator,
  textnumbergenerator,
} from "../../../utils/Util";
import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableSkeleton from "../Common/TableSkeleton";
import { PendingTaskModel } from "./PendingTaskModel";
interface PendingTaskProps {
  page?: string;
  y?: string | number;
  params?: any;
  props: any;
  projectdata: any;
  setShow:any,
  setSelectedAssignees:any
}
const PendingTask: React.FC<PendingTaskProps> = ({
  page,
  y,
  params,
  props,
  projectdata,
  setShow,
  setSelectedAssignees
  // setSelectedAssignees,
  // setIsTableVisible
}) => {
  const user = useSelector(userData);
  const { data: taskData, isLoading: isTaskLoading } = useGetPendingTasks(
    { assigneeId: user?.id },
    user?.id
  );
  const navigate = useNavigate();
  const [projectdatapending, setprojectdatapending] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setisLoading] = useState(false);

  const openModal = useCallback(() => setIsOpen(true), []);
  const closeDrawer = useCallback(() => setIsOpen(false), []);

  useEffect(() => {
    setisLoading(isTaskLoading);
  }, [isTaskLoading]);

  let RANDOM_USERS: any = [];

  for (let el in projectdata) {
    RANDOM_USERS.push({ id: projectdata[el].id, name: projectdata[el].name });
  }

  const data = RANDOM_USERS.map((d: any, i: any) => ({
    email: d.name,
    key: d.name,
    name: d.name,
    href: "#",
    src:
      d.imageThumbPath ||
      `https://i1.wp.com/avatar-management--avatars.us-west-2.prod.public.atl-paas.net/initials/${initialsgenerator(
        d.name
      )}-${textnumbergenerator(d.name)}.png?ssl=1`,
  }));

  const showPendingTask = (projectId: any, data: any) => {
    setisLoading(true);
    BooksService.getPendingTaskByProject(projectId, 1, user?.id).then(
      (res: any) => {
        setisLoading(false);
        setprojectdatapending(res.result);
      }
    );
  };



  const getPendingTaskList = useMemo(() => {
    return taskData?.result?.items?.map((task: any) => ({
      ...task,
      assignee: task?.assignee?.map((itm: any) => ({
        ...itm,
        thumb: itm?.imageThumbPath,
      })),
    }));
  }, [taskData]);

  const columns = [
    {
      title:(
        <Typography.Text ellipsis>
           S. No.
        </Typography.Text>
      ),
      dataIndex: "serial",
      key: "serial",
       width: "15%",
      render: (text: string, record: any, index: number) => (
        <div className="pl-4">{index + 1}</div>
      ),
    },
    {
      title: "Project",
      dataIndex: "name",
      key: "name",
      width: "40%",
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.name - b.name,
      render: (name: any, record: any) => (
        <Typography.Link
      ellipsis
          onClick={() => {
            setprojectdatapending([]);
            showPendingTask(record?.id, record?.name);
            openModal();
          }}
        >
          <Tooltip title={name}>{name}</Tooltip>
        </Typography.Link>
      ),
    },
    {
      title: "Date",
      dataIndex: "createDate",
      key: "createDate",
      // width:150,
      showSorterTooltip:false,
      sorter: (a: any, b: any) => a.createDate - b.createDate,
      render: (date: any) => <Typography.Text ellipsis>{moment(date).format("DD MMM YYYY")}</Typography.Text>,
    },
    {
      title: "Team",
      dataIndex: "assignee",
      key: "assignee",
      
      render: (assignee: any, item: any, uKey: any) => (
        // <div style={{ cursor: "pointer" }}>
        //   <AssigneeAvatarSmall maxCount={1} data={assignee} />
        // </div>
           <Button
           className="dash-link"
           type="link"
           style={{ color: "rgb(54, 70, 99)", padding: 0 }}
           onClick={() => {
            setShow("Assignees");
            setSelectedAssignees(item?.assignee);
            // handleButtonClick(item?.assignee)
          }}
         >
           {item?.assignee?.length} Assignee
         </Button>
      ),
      // width: "16%",
    },
  ];

  return (
    <Row style={{ padding: 16 }} className="roundedCornerSmall bg-white mr-2 Resposivecostom Resposivecostom1">
      <Col span={24} className="pb-2">
        <Row justify={"end"} className="mb-2">
          <Col span={24}>
            <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorTextPlaceholder: "#95a5a6",
                    fontSize: 14,
                    colorPrimaryHover: "none",
                    fontSizeIcon: 10,
                    borderRadius: 4,
                    controlPaddingHorizontal: 4,
                    controlHeight: 27,
                    algorithm: true,
                    fontWeightStrong: 600,
                  },
                },
              }}
            >
              <Row>
                <Col
                  span={8}
                  style={{
                    fontWeight: 600,
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    // paddingLeft: "25px",
                    color: "rgb(54, 70, 99)",
                    margin: 0,
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                >
                  Pending Tasks
                </Col>
              </Row>
            </ConfigProvider>
          </Col>
          <PendingTaskModel
            projectdatapending={projectdatapending}
            isLoading={isLoading}
            isOpen={isOpen}
            closeDrawer={closeDrawer}
          />
        </Row>
      </Col>

      <Col span={24} style={{ height: "287px" }}>
        {isLoading ? (
          <TableSkeleton
            columns={columns}
            scroll={{ y: "75vh" }}
            rows={8}
            style={{
              scrollbarWidth: "none",
              borderTop: "solid 1px #b2bec3",
            }}
          />
        ) : (
          <Table
            dataSource={getPendingTaskList}
            columns={columns}
            pagination={false}
            scroll={{ y: "255px" }}
            // scroll={{ y: y ?? "auto" }}
            style={{
              scrollbarWidth: "none",
              borderTop: "solid 1px #b2bec3",
            }}
            locale={{
              emptyText: (
                <img
                  style={{ paddingBottom: "128px", width: 110 , }}
                  src={process.env.PUBLIC_URL + "/img-antd.svg"}
                  alt="No Task"
                />
              ),
            }}
          />
        )}
      </Col>
  
    </Row>
  );
};

export default React.memo(PendingTask);
