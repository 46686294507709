import React, { useMemo, useState } from "react";
import { useGetLogsList } from "../../../Services/RQDashboardService";
import {
  Avatar,
  Button,
  Card,
  Col,
  ConfigProvider,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import { statusbackgroundfont, statuscolorfont } from "../../../utils/Util";
import { SearchOutlined, UserAddOutlined } from "@ant-design/icons";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import "../../../../src/common/common.css";

import { userData } from "../../../store/slices/user-slice";
import { useSelector } from "react-redux";
import { projectData as PD } from "../../../store/slices/project-slice";
import ActivityMessageCradSkeleton from "../Common/ActivityMessageCradSkeleton";
import { AddEditApis } from "../../../Services/RQTaskService";
interface ActiviyLogsProps {
  page?: string;
  y?: string | number;
  params?: any;
}

const ActiviyLogs: React.FC<ActiviyLogsProps> = ({ page, y, params }) => {
  const user = useSelector(userData);
  const project = useSelector(PD);
  const { isLoading, data: logData } = useGetLogsList(
    params,
    user?.id,
    project?.id
  );
  const [projectList, setProjectList] = useState<any[]>([]);
  const [showFullText, setShowFullText] = useState(false);
  const navigate = useNavigate();
  const [logPayload, setLogPayload] = useState<{
    assigneeId?: string | undefined | null;
  }>({
    assigneeId: "",
  });
  const getLogsList = useMemo(() => {
    // Filter logData based on the selected assigneeId in logPayload
    const filteredLogs = logData?.result?.filter(
      (log: any) =>
        !logPayload?.assigneeId || log?.assigneeId === logPayload?.assigneeId
    );
    return filteredLogs || [];
  }, [logData, logPayload]);
  const sanitizeText = (text: string | undefined, maxLength: number) => {
    if (!text) return "";
    const strippedText = text.replace(/(<([^>]+)>)/gi, "");
    return strippedText.length > maxLength
      ? `${strippedText.substring(0, maxLength)}...`
      : strippedText;
  };
  const [searchVisible, setSearchVisible] = useState(false);
  // const [searchQuery, setSearchQuery] = useState("");
  const [assigneeSearch, setAssigneeSearch] = useState<{
    search: string | undefined;
  }>({
    search: undefined,
  });
  const [refresh, setRefresh] = useState<boolean>(false);
  const { useAssignee } = AddEditApis;
  const { data: userAssigneeList, isPending: isAssigneePending } = useAssignee({
    id: project?.id,
    ...assigneeSearch,
  });
  const assigneeOptions = useMemo(() => {
    return userAssigneeList?.result?.items?.map((itm: any) => itm) || [];
  }, [userAssigneeList, project?.id]);

  //Table
  const columns = useMemo(
    () => [
      {
        key: "logs",
        dataIndex: "logs",
        render: (_: any, item: any) => (
          <Card
            loading={isLoading}
            className="activiyLogCard"
            title={item?.name}
            size="small"
            style={{ border: "none" }}
          >
            <Card.Meta
              avatar={
                item?.thumb ? (
                  <Tooltip
                    title={
                      <img
                        src={item?.thumb}
                        alt="tooltip"
                        style={{ width: "90px" }}
                      />
                    }
                  >
                    <Avatar
                      shape="circle"
                      size={35}
                      style={{ margin: "3px -7px" }}
                      src={item?.thumb}
                    />
                  </Tooltip>
                ) : (
                  <Avatar size={35} icon={<UserAddOutlined />} />
                )
              }
              title={
                <span
                  style={{
                    fontFamily: "Open Sans",
                    fontSize: 14,
                    cursor: "pointer",
                    // borderTop: "2px solid rgb(240, 242, 244)",
                  }}
                  onClick={() =>
                    navigate("/reports/user", {
                      state: {
                        userid: item?.assigneeId,
                      },
                    })
                  }
                >
                  {item?.assigneeName}
                </span>
              }
              description={
                <Row style={{ marginTop: -8 }}>
                  <Col
                    xs={24}
                    sm={18}
                    md={15}
                    lg={18}
                    style={{ fontSize: "13px", color: "#636e72" ,

                   
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    <Tooltip 
                    title={moment(item?.cDate)?.format(
                      "MMM DD YYYY, [at] hh:mm A"
                    )}>

                    <Typography.Text ellipsis style={{fontSize:'small'}}>

                    {moment(item?.cDate).calendar(null, {
                      sameDay: "[Today at] hh:mm A",
                      nextDay: "[Tomorrow at] hh:mm A",
                      nextWeek: "dddd",
                      lastDay: "[Yesterday at] hh:mm A",
                      lastWeek: "[Last] dddd [at] hh:mm A",
                      sameElse: "MMM DD YYYY, [at] hh:mm A",
                    })}
                    </Typography.Text>
                    </Tooltip>
                  </Col>
                  <Col xs={24} sm={6} md={3} lg={6}>
                    <Button
                      size="small"
                      style={{
                        color: "rgb(16, 124, 16)",
                        backgroundColor: "rgb(223, 246, 221)",
                        width: "55px",
                        border: "none",
                        height: "16px",
                      }}
                      onClick={() => navigate("/task/" + item?.tId)}
                    >
                      <Tooltip title={item?.uKey}>
                        <span
                          style={{
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {item?.uKey}
                        </span>
                      </Tooltip>
                    </Button>
                  </Col>
                </Row>
              }
            />

            <div style={{ marginTop: 10 }}>
              <div
                style={{ borderLeft: "3px solid #C3DFFF", margin: "0px -12px" }}
              >
                <div style={{ marginLeft: 10 }}>
                  <Tooltip
                    title={
                      <div>
                        {/* Use the sanitized text */}
                        {sanitizeText(item?.remarks, 300)}
                      </div>
                    }
                  >
                    <p
                      style={{
                        fontSize: 11.8,
                        color: "#636e72",

                        marginBottom: 5,
                      }}
                    >
                      {item?.cngType === 4 ? (
                        <>
                          {item?.isDelay ? (
                            <span>
                              {item?.assigneeName} added a task as delay
                            </span>
                          ) : (
                            <span style={{ fontWeight: "600" }}>
                              {item?.assigneeName} added The comment :-
                            </span>
                          )}
                          <div>
                            {showFullText ? (
                              <div onClick={() => setShowFullText(true)}>
                                {/* Show truncated text */}
                                {item?.remarks}
                              </div>
                            ) : (
                              <div>
                                {/* Use the sanitized text */}
                                {sanitizeText(item?.remarks, 78)}
                              </div>
                            )}
                          </div>
                        </>
                      ) : (
                        <span style={{ fontWeight: "600" }}>
                          {sanitizeText(item?.remarks, 78)}
                        </span>
                      )}
                    </p>
                  </Tooltip>

                  {(item?.cngType === 3 || item?.cngType === 2) && (
                    <div>
                      <p
                        style={{
                          marginBottom: 27,
                          fontSize: 12,
                          color: "rgb(146, 159, 172)",
                          display: "flex",
                        }}
                      >
                        <Tooltip
                          title={
                            <div>
                              {/* Use the sanitized text */}
                              {sanitizeText(item?.from, 60)}
                            </div>
                          }
                        >
                          <div>
                            <span
                              style={{
                                color: statuscolorfont(item?.from),
                                borderRadius: "3px",
                                fontSize: 12.5,
                                backgroundColor: statusbackgroundfont(
                                  item?.from
                                ),
                              }}
                            >
                              {item?.from && (
                                <div>
                                  {/* Use the sanitized text */}
                                  {sanitizeText(item?.from, 15)}
                                </div>
                              )}
                            </span>
                          </div>
                        </Tooltip>
                        <span
                          className="pl-2 pr-2"
                          style={{ color: "#636e72" }}
                        >
                          {" -> "}
                        </span>

                        <Tooltip
                          title={
                            <div>
                              {/* Use the sanitized text */}
                              {sanitizeText(item?.to, 80)}
                            </div>
                          }
                        >
                          <div>
                            <span
                              style={{
                                color: statuscolorfont(item?.to),
                                fontSize: 12.5,
                                borderRadius: "3px",
                                backgroundColor: statusbackgroundfont(item?.to),
                              }}
                            >
                              {item?.to && (
                                // <div
                                //   dangerouslySetInnerHTML={{
                                //     __html: getEllipsisText(item?.to,35),
                                //   }}
                                // />
                                <div>
                                  {/* Use the sanitized text */}
                                  {sanitizeText(item?.to, 23)}
                                </div>
                              )}
                            </span>
                          </div>
                        </Tooltip>
                      </p>
                    </div>
                  )}
                  {(item?.from || item?.to) && !item?.remarks && (
                    <p
                      style={{
                        marginTop: 0,
                        marginBottom: 10,
                        fontSize: 14,
                        color: "rgb(146, 159, 172)",
                      }}
                    >
                      {item?.remarks}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </Card>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading, page, navigate, projectList]
  );
  return (
    <div>
      {isLoading ? (
        <ActivityMessageCradSkeleton rows={6} />
      ) : (
        <ConfigProvider
          theme={{
            components: {
              Table: {
                rowHoverBg: "none",
              },
            },
          }}
        >
          <Table
            className="Activitycss"
            showHeader={false}
            size="small"
            title={() => (
              <Row justify={"space-between"} align={"middle"}>
                <Col span={13}>
                  <Typography.Link
                    ellipsis
                    style={{
                      paddingLeft: "12px",
                      color: "rgb(54, 70, 99)",
                      fontWeight: "600",
                      marginBottom: "0px",
                    }}
                  >
                    Activity / Message
                  </Typography.Link>
                </Col>
                <Col className="ml-2 mt-1">
                  <SearchOutlined
                    style={{
                      cursor: "pointer",
                      fontSize: 17,
                      color: searchVisible ? "#1677ff" : undefined,
                    }}
                    onClick={() => setSearchVisible(!searchVisible)}
                  />
                </Col>
                <Col span={24} className="ml-2 mt-2">
                  {searchVisible && (
                    <Select
                      placeholder={"Select Assignee"}
                      variant="borderless"
                      className="w100 borderLessInput"
                      allowClear
                      showSearch
                      onSelect={(value) => {
                        setLogPayload((pre: any) => ({
                          ...pre,
                          assigneeId: value,
                        }));
                      }}
                      onClear={() => {
                        setLogPayload((pre: any) => ({
                          ...pre,
                          assigneeId: undefined,
                        }));
                        setRefresh(!refresh);
                      }}
                      options={assigneeOptions}
                      onSearch={(value) => {
                        setAssigneeSearch((pre: any) => ({
                          ...pre,
                          search: value,
                        }));
                        setRefresh(!refresh);
                      }}
                    />
                  )}
                </Col>
              </Row>
            )}
            columns={columns}
            dataSource={getLogsList}
            scroll={{ y: y ?? "auto" }}
            pagination={false}
            locale={{
              emptyText: (
                <Typography.Title
                  ellipsis
                  level={4}
                  style={{
                    textAlign: "center",
                    paddingTop: "147px",
                    height: "73.1vh",
                  }}
                >
                  <img
                    style={{ paddingTop: "180px", width: 110 }}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                </Typography.Title>
              ),
            }}
          />
            {/* <Button
            loading={isLoading} 
            size="small"
            type="text"
            // onClick={loadMoreLogs} 
            style={{ margin:'10px 155px' }}
          >
            Load More
          </Button> */}
        </ConfigProvider>
      )}
    </div>
  );
};

export default React.memo(ActiviyLogs);
