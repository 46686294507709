import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../";

type User = {
  imagePath: string;
  imageThumb: string;
  token: string;
  name: string;
  tokenExpiry: number | null;
  id: string;
  role: string;
  email: string;
  userName: string;
  company: string | null;
  status: number | null;
};

type TInitialState = {
  user: User;
  loading: boolean;
};

const initialState: TInitialState = {
  user: {
    imagePath: "",
    imageThumb: "",
    token: "",
    name: "",
    tokenExpiry: null,
    id: "",
    role: "",
    email: "",
    userName: "",
    company: null,
    status: null,
  },
  loading: false,
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserData: (state, action: PayloadAction<User>) => {
      state.user = action.payload;
    },
    resetUserData: (state) => {
      state.user = initialState.user;
    },
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
});

export const { setUserData, resetUserData, setLoading } = userSlice.actions;

export const userData = (state: RootState): User => state?.user?.user;
export const loading = (state: RootState): boolean => state?.user?.loading;
export const selectUserId = (state: RootState): string => state?.user?.user?.id;

export default userSlice.reducer;
