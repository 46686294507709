// import React, { Suspense, useEffect } from "react";
// import {
//   Outlet,
//   Route,
//   Routes,
//   useLocation,
//   useNavigate,
// } from "react-router-dom";
// import { AppRoutesPath } from "./AppRoutesPath";
// import Dashboard from "../pages/dashboard/Dashboard";
// import Board from "../pages/Board";
// import UserReport from "../pages/UserReport";
// import Report from "../pages/Report";
// import Project from "../pages/Project";
// import Issue from "../pages/Issue";
// import Sprint from "../pages/Sprint";
// import Log from "../pages/Log";
// import Branch from "../pages/Branch";
// import User from "../pages/User";
// import DashboardMain from "../pages/Dashboardtablecomponent/DashboardMain";
// import Backlog from "../pages/Backlog";
// import Meetings from "../pages/Meetings";
// import IssuesReport from "../pages/IssuesReport";
// import InHandReport from "../pages/InHandReport";
// import AdminDashboard from "../Admin/AdminPages/AdminDashboard/AdminDashboard";
// import ViewAll from "../pages/ViewAll";
// import Forgotpassword from "../pages/Forgotpassword";
// import Resetpassword from "../pages/Resetpassword";
// import { useSelector } from "react-redux";
// import { resetUserData, userData } from "../store/slices/user-slice";
// import { useDispatch } from "react-redux";
// import { isTokenNotExpired } from "../common/commonFunction";
// import NotFound from "../pages/NotFoundpage";
// import ViewAllTaskbord from "../pages/ViewAllTaskbord";
// import { Setting } from "../pages/setting/Setting";
// import Profile from "../components/profile";
// import { isUserAdmin } from "../utils/Util";
// // import Report from "../components/Reports/Report";
// import Reporters from "../components/Reports/Report";
// import Reports from "../components/Reports/Report";
// const AsyncMainLayout = React.lazy(() => import("../components/MainLayout"));
// const AsyncLogin = React.lazy(() => import("../pages/Login"));

// type RouteConfig = {
//   caseSensitive: boolean;
//   path: string;
//   element: React.ReactNode;
// };

// const App = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const user = useSelector(userData);
//   const dispatch = useDispatch();

//   useEffect(() => {
//     const normalizedPath = location.pathname.replace(/\/$/, ""); // Remove trailing slash if present
//     if (user?.token) {
//       if (isTokenNotExpired(user.token)) {
//         if (location.pathname === "/" || normalizedPath === "/login") {
//           navigate("/dashboard");
//         }
//       } else {
//         dispatch(resetUserData());
//         navigate("/login", { replace: true });
//       }
//     } else if (location.pathname === "/") {
//       navigate("/login", { replace: true });
//     } else {
//       navigate("/login");
//     }
//     // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [location.pathname, navigate, user]);

//   return (
//     <Suspense fallback={<div className="loadinglazy"></div>}>
//       <AsyncMainLayout>
//         <Outlet />
//       </AsyncMainLayout>
//     </Suspense>
//   );
// };


// const routeDefinitions: RouteConfig[] = [
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.dashboard,
//     element: <Dashboard />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.viewall,
//     element: <ViewAll />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.taskboard,
//     element: <ViewAllTaskbord />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.profile,
//     element: <Profile />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.admindashboard,
//     element: <AdminDashboard />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.board,
//     element: <Board />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.meetings,
//     element: <Meetings />,
//   },
//   // {
//   //   caseSensitive: true,
//   //   path: AppRoutesPath.mileStone,
//   //   element: <h1>MileStone</h1>,
//   // },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.backLog,
//     element: <Backlog />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.peoples,
//     element: <UserReport />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.reports.home,
//     element: <Reports />,
//   },
//   {
//     caseSensitive: true,
//     path: AppRoutesPath.task,
//     element: <DashboardMain />,
//   },
//   {
//     caseSensitive: true,
//     path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.issues}`,
//     element: <IssuesReport />,
//   },
//   {
//     caseSensitive: true,
//     path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.inHand}`,
//     element: <InHandReport />,
//   },
//   {
//     caseSensitive: true,
//     path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`,
//     element: <Report />,
//   },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.project}`,
//   //   element: <Project />,
//   // },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.issue}`,
//   //   element: <Issue />,
//   // },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.sprint}`,
//   //   element: <Sprint />,
//   // },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.logDetail}`,
//   //   element: <Log />,
//   // },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.branch}`,
//   //   element: <Branch />,
//   // },
//   // {
//   //   caseSensitive: true,
//   //   path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.user}`,
//   //   element: <User />,
//   // },

// ];
//  const adminRoutes: RouteConfig[] = [
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.project}`,
//      element: <Project />,
//    },
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.issue}`,
//      element: <Issue />,
//    },
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.sprint}`,
//      element: <Sprint />,
//    },
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.logDetail}`,
//      element: <Log />,
//    },
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.branch}`,
//      element: <Branch />,
//    },
//    {
//      caseSensitive: true,
//      path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.user}`,
//      element: <User />,
//    },
//    {
//     caseSensitive: true,
//     path: AppRoutesPath.setting.home,
//     element: <Setting />,
//   },
//  ];
 
// const ApplicationRoutes = () => {
//   return (
//     <Routes>
//       <Route element={<AsyncLogin />} path="/login" />
//       <Route path="/forgotpassword" element={<Forgotpassword />} />
//       {/* <Route path="/profile" element={<Profile />} /> */}
//       <Route
//         path="/accountrecovery/ResetPassword"
//         element={<Resetpassword />}
//       />
//       <Route path="" element={<App />}>
//       {routeDefinitions.map((route) => (
//           <Route
//             key={route.path}
//             caseSensitive={route.caseSensitive}
//             path={route.path}
//             element={route.element}
//           />
//         ))}

//         {isUserAdmin()&&adminRoutes.map((route) => (
//           <Route
//             key={route.path}
//             caseSensitive={route.caseSensitive}
//             path={route.path}
//             element={route.element}
//           />
//         ))} 
//       </Route>
//       <Route path="*" element={<NotFound />} />
//     </Routes>
//   );
// };

// export default ApplicationRoutes;
import React, { Suspense, useEffect } from "react";
import {
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { AppRoutesPath } from "./AppRoutesPath";
import Dashboard from "../pages/dashboard/Dashboard";
import Board from "../pages/Board";
import UserReport from "../pages/UserReport";
import Report from "../pages/Report";
import Project from "../pages/Project";
import Issue from "../pages/Issue";
import Sprint from "../pages/Sprint";
import Log from "../pages/Log";
import Branch from "../pages/Branch";
import User from "../pages/User";
import DashboardMain from "../pages/Dashboardtablecomponent/DashboardMain";
import Backlog from "../pages/Backlog";
import Meetings from "../pages/Meetings";
import InHandReport from "../pages/InHandReport";
import AdminDashboard from "../Admin/AdminPages/AdminDashboard/AdminDashboard";
import ViewAll from "../pages/ViewAll";
import Forgotpassword from "../pages/Forgotpassword";
import Resetpassword from "../pages/Resetpassword";
import { useSelector } from "react-redux";
import { resetUserData, userData } from "../store/slices/user-slice";
import { useDispatch } from "react-redux";
import { isTokenNotExpired } from "../common/commonFunction";
import NotFound from "../pages/NotFoundpage";
import ViewAllTaskbord from "../pages/ViewAllTaskbord";
import { Setting } from "../pages/setting/Setting";
import Profile from "../components/profile";
import { isUserAdmin } from "../utils/Util";
// import Report from "../components/Reports/Report";
import Reports from "../components/Reports/Report";
import IssuesReport from "../pages/UserDetail/UpcomingMeeting/IssuesReport";
const AsyncMainLayout = React.lazy(() => import("../components/MainLayout"));
const AsyncLogin = React.lazy(() => import("../pages/Login"));

type RouteConfig = {
  caseSensitive: boolean;
  path: string;
  element: React.ReactNode;
};

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = useSelector(userData);
  const dispatch = useDispatch();

  useEffect(() => {
    
    const normalizedPath = location.pathname.replace(/\/$/, ""); // Remove trailing slash if present
    if (user?.token) {
      if (isTokenNotExpired(user.token)) {
        if (location.pathname === "/" || normalizedPath === "/login") {
          navigate("/dashboard");
        }
      } else {
        dispatch(resetUserData());
         navigate("/login", { replace: true });
      }
    } else if (location.pathname === "/") {
      navigate("/login", { replace: true });
    } else {
       navigate("/login");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, navigate, user]);

  return (
    <Suspense fallback={<div className="loadinglazy"></div>}>
      <AsyncMainLayout>
        <Outlet />
      </AsyncMainLayout>
    </Suspense>
  );
};

const routeDefinitions: RouteConfig[] = [
  {
    caseSensitive: true,
    path: AppRoutesPath.dashboard,
    element: <Dashboard />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.viewall,
    element: <ViewAll />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.taskboard,
    element: <ViewAllTaskbord />,
  },
  // {
  //   caseSensitive: true,
  //   path: AppRoutesPath.profile,
  //   element: <Profile />,
  // },
  {
    caseSensitive: true,
    path: AppRoutesPath.admindashboard,
    element: <AdminDashboard />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.board,
    element: <Board />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.meetings,
    element: <Meetings />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.backLog,
    element: <Backlog />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.peoples,
    element: <UserReport />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.reports.home,
    element: <Reports />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.task,
    element: <DashboardMain />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.issues}`,
    element: <IssuesReport />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.inHand}`,
    element: <InHandReport />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.reports.home}/${AppRoutesPath.reports.userReport}`,
    element: <Report />,
  },
];

const adminRoutes: RouteConfig[] = [
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.project}`,
    element: <Project />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.issue}`,
    element: <Issue />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.sprint}`,
    element: <Sprint />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.logDetail}`,
    element: <Log />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.branch}`,
    element: <Branch />,
  },
  {
    caseSensitive: true,
    path: `${AppRoutesPath.setting.home}/${AppRoutesPath.setting.user}`,
    element: <User />,
  },
  {
    caseSensitive: true,
    path: AppRoutesPath.setting.home,
    element: <Setting />,
  },
];

const ApplicationRoutes = () => {
  const isAdmin = isUserAdmin(); // Check if the user is an admin

  return (
    <Routes>
      <Route element={<AsyncLogin />} path="/login" />
      <Route path="/forgotpassword" element={<Forgotpassword />} />
      <Route
           path="/accountrecovery/ResetPassword"
        element={<Resetpassword />}
      />
      <Route path="" element={<App />}>
        {routeDefinitions?.map((route) => (
          <Route
            key={route.path}
            caseSensitive={route.caseSensitive}
            path={route.path}
            element={route.element}
          />
        ))}

        {/* Admin routes: Render only if the user is an admin */}
        {isAdmin &&
          adminRoutes.map((route) => (
            <Route
              key={route.path}
              caseSensitive={route.caseSensitive}
              path={route.path}
              element={route.element}
            />
          ))}
      </Route>
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};

export default ApplicationRoutes;
