import { Link, useLocation } from "react-router-dom";
import AddEditTask from "../../common/AddEditTask";
import { Breadcrumb, Col, Row } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import AddEditTaskDash from "../../common/AddEditTaskDash";

const DashboardMain = () => {
  const location = useLocation();
  const urlArray = location.pathname.split("/");
  const id = urlArray[urlArray.length - 1];
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/board"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/board"} className="BreadcrumbLink">
                    Board
                  </Link>
                ),
              },
              {
                title: "Task Detail",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{
        // height:"80vh"
      }}>
        <Col span={24}>
          <AddEditTaskDash showDrawer={false} id={id} onDismiss={() => {}} />
        </Col>
      </Row>
    </>
  );
};
export default DashboardMain;
