import { useMutation, useQuery } from "@tanstack/react-query";
import HTTPSCalls from "./HTTPCalls";
import { queryClient } from "..";
import saveAs from "file-saver";

export const AddEditApis = {
  useTask: () =>
    useMutation({
      mutationFn: async (payload: any) => {
        const result = await addEditTask(payload);
        return result;
      },
      onSuccess: (data: any) => {
        if (!data?.error) {
          queryClient.invalidateQueries({
            queryKey: ["logsList"],
          });
          queryClient.invalidateQueries({
            queryKey: ["taskGetById"],
          });
          queryClient.invalidateQueries({
            queryKey: ["logsListDashboard"],
          });
          queryClient.invalidateQueries({
            queryKey: ["taskUkey"],
          });
          
        }
      },
    }),
  useGetTask: (taskId: string | undefined | null = undefined) => {
    return useQuery({
      enabled: !!taskId,
      queryKey: ["taskGetById", taskId],
      queryFn: () => getTaskById(taskId, taskId),
    });
  },
  useBranch: (payload: any = {}) => {
    return useQuery({
      queryKey: ["taskBranchListOptions", payload],
      queryFn: () => getBranchList(payload),
    });
  },
  useIssue: (payload: any = {}) => {
    return useQuery({
      queryKey: ["taskIssueListOptions", payload],
      queryFn: () => getIssueList(payload),
    });
  },
  useProject: (
    payload: any,
    taskId: string | undefined | null = undefined,
    callItSelfForChild: boolean | undefined = undefined
  ) => {
    return useQuery({
      enabled: !!!taskId && !callItSelfForChild,
      queryKey: ["taskProjectList", payload],
      queryFn: () => getProjectsList(payload),
    });
  },
  useUser: (payload: any = {}) => {
    return useQuery({
      queryKey: ["taskUserListOptions", payload],
      queryFn: () => getUserList(payload),
    });
  },
  useAssignee: (payload: any) => {
    return useQuery({
      queryKey: ["taskAssigneeListOptions", payload],
      queryFn: () => getAssigneeList(payload),
    });
  },
  useUkey: (
    projectId: string,
    currentUkey: string,
    taskId: string | undefined | null = undefined
  ) => {
    return useQuery({
      enabled: !!!taskId,
      queryKey: ["taskUkey", { projectId, currentUkey }],
      queryFn: () => getCurrentUkey(projectId),
    });
  },
  useCategory: (
    payload: any = {},
    taskId: string | undefined | null = undefined
  ) => {
    return useQuery({
      enabled: !!taskId,
      queryKey: ["taskCategoryListOptions", payload],
      queryFn: () => getLabelList(payload),
    });
  },
  useSprint: (
    payload: any = {},
    taskId: string | undefined | null = undefined
  ) => {
    return useQuery({
      enabled: !!taskId,
      queryKey: ["taskSprintListOptions", payload],
      queryFn: () => getSprintList(payload),
    });
  },

  // useCsv: ( payload: any ) => {
  //   console.log("payloadpayload==",payload);
    
  //    let projectId = payload?.project?.map((e:any)=>e?.value)
  //   return useQuery({
  //     queryKey: ["getCsvDownload", projectId],
  //     queryFn: () => getCsvDownload(projectId),
  //   });
  // },
};

const getLabelList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.category.getCategoryListOptions, {
    ...payload,
  });

const getSprintList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.sprint.getSprintListOptions, {
    ...payload,
  });
const getTaskById = async (
  id: string | undefined | null,
  ukey: string | undefined | null
) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.task.getById + "?", {
    id,
    ukey,
  });


const addEditTask = async (payload: any) => {
  return HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.multipleTasks, payload);
};

const getCurrentUkey = async (projectId: string) =>
  await HTTPSCalls.GET(HTTPSCalls.ENDPOINTS.project.getUkey + "?", {
    projectId,
  });

const getAssigneeList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getAssigneeListOptions, {
    ...payload,
    length:1500
  });

const getUserList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.user.getUserListOptions, {
    ...payload,
    length:1500
  });

const getBranchList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.branch.getBranchListOptions, {
    ...payload,
  });

const getIssueList = async (payload: any) =>
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.issueType.getLabelListOptions, {
    ...payload,
  });

const getProjectsList = async (payload: any) =>
  
  await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.project.getProjectsListOptions, {
    ...payload,
  });
 export const getCsvDownload = async (payload: any) =>{
  // console.log("ProjectProject ==",Project);
  
    let response:any = await HTTPSCalls.POST(HTTPSCalls.ENDPOINTS.task.getCsv+ "?",  {
      ...payload,
    },
    {
      start: 0,
      length: 150,
    });
    const blob = new Blob([response], {type: 'application/csv' });
    saveAs(blob, "file.csv"); 
    return response;
  }
      
