import {
  Button,
  Col,
  ConfigProvider,
  message,
  Row,
  Select,
  Table,
  Tooltip,
  Typography,
} from "antd";
import React, { useMemo, useState } from "react";
// import BooksService from "../../../Services/Books";
import { useGetTaskBoardList } from "../../../Services/RQDashboardService";
import {
  addStatusChangeLog,
  statusconvertor,
} from "../../../utils/Util";
import { Link, useNavigate } from "react-router-dom";
// import Loader from "../../../components/commoncomponents/Loader";
import HTTPSCalls from "../../../Services/HTTPCalls";
import { useDispatch, useSelector } from "react-redux";
import { setLoading, userData } from "../../../store/slices/user-slice";
import TableSkeleton from "../Common/TableSkeleton";
import { refreshDashboardmain } from "../../../store/slices/refresh-slice";
const TaskBoard = ({ setDefaultUser, props, assigneedata }: any) => {
  const dispatch = useDispatch();
  const user = useSelector(userData);
  const { isLoading, data: taskList  } = useGetTaskBoardList(

    {
      start: 0,
      length: 17000,
      assigneeId: user?.id,
      sort: 1,
      isDone: 1,
      isBacklog: 1,
      isPriority: 1,
    },
    user?.id,
   
  );
  const [selectedPriority, setSelectedPriority] = useState(4);

  const dataList = useMemo(() => {

    return taskList?.result?.map((task: any) => ({
      
      ...task,
      project: task?.project[0],
      tStatus: { label: statusconvertor(task?.tStatus), value: task?.tStatus },
    }));
  }, [taskList,]);
  // console.log("dataList =",dataList)
  const navigate = useNavigate();
  const filteredData = dataList?.filter((item: any) => {
    if (selectedPriority === 4) {
      return true; // Show all items if 'All' is selected
    } else {
      return item?.priority === selectedPriority;
      
    }
  });

  const statusOption = useMemo(
    
    () => [
      { label: "Backlog", value: 0 },
      { label: "Todo", value: 1 },
      { label: "Need to Discuss", value: 2 },
      { label: "In Progress", value: 3 },
      { label: "Developed", value: 7 },
      { label: "Duplicate", value: 8 },
      { label: "UAT", value: 4 },
      { label: "Testing", value: 5 },
      { label: "Done", value: 6 },
    ],
    []
  );
  const priorityOptions = [
    { label: "All", value: 4 },
    { label: "High", value: 2 },
    { label: "Medium", value: 1 },
    { label: "Low", value: 0 },
  ];

  // Handle change function Api

  // ...

  const handleChange = async (record: any, itm: any) => {
    setLoading(false);
    try {
      const res = await HTTPSCalls.POST(
        HTTPSCalls.ENDPOINTS.task.updateStatus + "?",
        {},
        {
          tid: record?.id,
          tstatus: itm?.value,
        }
      );
      // console.log("Update response:", res);
      // Show success notification
      if (res?.status) {
        message.success({
          content: "Task Status updated successfully.",
          duration: 2, // You can adjust the duration if needed
        });
        let task: any = {
          ...res?.result,
          UKey: res?.result?.uKey,
          _id: res?.result?.id,
        };
        await addStatusChangeLog(task, task?.tStatus, record?.tStatus?.value,2,record?.project);
         
        
        return res;
      } else {
        message.error({
          content: res?.message,
        });
      }
    } catch (error) {
      console.error("Error updating task status:", error);

      // Show error notification
      message.error({
        content: "Error Updating TaskBoard Status.",
        duration: 2, // You can adjust the duration if needed
      });
    } finally {
      dispatch(refreshDashboardmain());
      setLoading(true);
    }
  };

  const priorityconvertor = (priority: any) => {
    let color: any;
    switch (priority) {
      case 2:
        color = "#ff8585";
        return (
          <span style={{ color }}>
            {/* <CaretUpOutlined label="high" /> */}
            High
          </span>
        );
      case 1:
        color = "#ffd19e";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="medium" /> */}
            Medium
          </span>
        );
      case 0:
        color = "#d9d9d9";
        return (
          <span style={{ color }}>
            {/* <CaretDownOutlined label="low" /> */}
            Low
          </span>
        );
      default:
        return null;
    }
  };
  const columns = useMemo(
    () => [
      {
        title:(
          <Typography.Text ellipsis>
             S. No.
          </Typography.Text>
        ),
        dataIndex: "serial",
        key: "serial",
          width: "1%",
        render: (text: string, record: any, index: number) => index + 1
       
      },
      {
        title: (
          <span className="ml-2" style={{ fontWeight: 600 }}>
            Ukey
          </span>
        ),
        dataIndex: "uKey",
        key: "uKey",
        showSorterTooltip:false,
        sorter: (a: any, b: any) => a.uKey - b.uKey,
        render: (uKey: any ,record:any) => (
        
          <Button
          size="small"
          className="resume-btn-sm mt-1 ml-2"
          style={{
            // backgroundColor: "rgb(204, 229, 255)",
            color: "rgb(16, 124, 16)",
            backgroundColor: "rgb(223, 246, 221)",
            width: "6vw",
            border: "none",
          }}
          onClick={() => navigate("/task/" + record?.id)}
        >
          <Tooltip title={uKey}>
            <span
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {uKey}
            </span>
          </Tooltip>
        </Button>
        ),
        //  width: "25%",
      },
      {
        title: <span style={{ fontWeight: 600 }}>Title</span>,
        dataIndex: "title",
        key: "title",
        showSorterTooltip:false,
        sorter: (a: any, b: any) => a.title - b.title,
        render: (title: any) => (
          <Tooltip title={title}>
            <Typography.Text ellipsis>
            {title}
            </Typography.Text>
            </Tooltip>
        ),
        // width: "50%",
      },
      {
        title:"Priority",
        dataIndex: "priority",
        key: "priority",
        showSorterTooltip:false,
        sorter: (a: any, b: any) => a.priority - b.priority,
        render: (itm: any) => priorityconvertor(itm),
        // width: "18%",
      },
      {
        title: "Status",
        dataIndex: "tStatus",
        key: "tStatus",
        showSorterTooltip:false,
        sorter: (a: any, b: any) => a.tStatus - b.tStatus,
        render: (itm: any, record: any) => (
          <Select
            labelInValue
            variant="borderless"
            // className="taskbord"
            size="small"
            style={{ width: 200 }}
            options={statusOption}
            defaultValue={itm}
            onSelect={(val) => {
              // console.log("record", record);
              // console.log("val = >>", val);
              handleChange(record, val);
            }}
          />
        ),
        // width: "30%",
      },
    ],
    [statusOption]
  );
  const onSearch = (value: string) => {
    // console.log("search:", value);
  };
  return (
    <Row className="roundedCornerSmall bg-white mr-2 Resposivecostom " style={{ padding: 16 }}>
      <Col span={24}>
        <Row justify={"end"} className="mb-2">
          <Col span={24}>
        <ConfigProvider
              theme={{
                components: {
                  Select: {
                    colorTextPlaceholder: "#95a5a6",
                    fontSize: 14,
                    colorPrimaryHover: "none",
                    // controlHeight: 32,
                    fontSizeIcon: 10,
                    // borderRadius: 4,
                    paddingSM: 4,
                    paddingXS: 4,
                    paddingXXS: 4,
                    lineWidth: 1,
                    borderRadius: 4,
                    borderRadiusLG: 4,
                    borderRadiusSM: 4,
                    borderRadiusXS: 4,
                    controlPaddingHorizontal: 4,
                    controlPaddingHorizontalSM: 4,
                    controlHeight: 27,
                    controlHeightLG: 27,

                    // fontSize: 14,
                    // controlHeight: 28,
                    // borderRadius: 1,
                    algorithm: true,
                    fontWeightStrong: 600,
                  },
                },
              }}
            >
              <Row align={"middle"} justify={"space-between"}>
                <Col
                span={5}
                  // xs={5}
                  style={{
                    fontWeight: 600,
                    fontFamily: "Open Sans",
                    fontSize: "16px",
                    // paddingTop: "4px",
                    // paddingLeft: "25px",
                    color: "rgb(54, 70, 99)",
                    margin: 0,
                    marginTop: 6,
                    marginBottom: 6,
                  }}
                >
                  Task Board
                </Col>
                <Col>
                  <Select
                    className="capTask-custom-select  ml-7" 
           
                    style={{ width: 190 }}
                    // defaultValue={priorityOptions[0]}
                    // onChange={handleChange}
                    onChange={(item: any) => setSelectedPriority(item)}
                    defaultValue={"All"}
                    options={priorityOptions}
                    showSearch
                    onSearch={onSearch}
                    optionFilterProp="label"
                  />
                <>
                  {assigneedata?.length <= 4 ? (
                    ""
                  ) : (
                    <Button
                    className="ml-9"
                      size="large"
                      // className="resume-btn-sm ml-2 mt-1"
                      style={{
                        backgroundColor: "rgb(204, 229, 255)",
                         width: "70px",
                         height:'27px',
                        border: "none",
                        borderRadius:5
                      }}
                    >
                      <Link
                        style={{
                          fontSize: 14,
                          fontFamily: "Open Sans",
                          cursor: "pointer",
                          color: "rgb(37, 49, 72)",
                        }}
                        to="/taskboard"
                      >
                        View all
                      </Link>
                    </Button>
                  )}
                  {/* </Row> */}
                </>
                </Col>
              </Row>
            </ConfigProvider>
          </Col>
        </Row>
        {/* <Loader loading={isLoading} /> */}
      </Col>

      <Col span={24}>
        <Col span={24}>
          {isLoading ? (
            <TableSkeleton
              columns={columns}
              // scroll={{ y: "40vh" }}
              rows={4}
              scroll={{ y:  "auto" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              dataSource={filteredData}
              columns={columns}
              pagination={false}
              // scroll={{ y: "80vh" }}
              style={{
                height: "22.5vh",
                overflowY: "auto",
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
              locale={{
                emptyText: (
                  <img
                    style={{  paddingBottom: "270px", width: 140 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Col>
    </Row>
  );
};

export default React.memo(TaskBoard);
