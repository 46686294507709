import React, { useState, useMemo } from "react";
import {
  Input,
  Button,
  Row,
  Col,
  Breadcrumb,
  Table,
  Typography,
  TableProps,
  message,
  Tag,
} from "antd";
import {
  ArrowLeftOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Link } from "react-router-dom";
import AddEditUser from "./UpcomingMeeting/AddEditUser";
import TableSkeleton from "../dashboard/Common/TableSkeleton";
import { useUserList } from "../../Services/RQUserService";
interface ProjectData {
  assigneeIds: { label: any; value: any }[];
  assignee: any[];
}
const UserDetail = () => {
  const [listParams, setListParams] = useState({
    start: 0,
    length: 15,
    name:""
  });
  const [copiedEmail, setCopiedEmail] = useState(null);
 
  const handleCopy = (email:any) => {
    setCopiedEmail(null);
    setTimeout(() => {
      setCopiedEmail(email);
      message.success("Copied to Email!");
      setCopiedEmail(null);
    }, 1000);
  };
  const { isLoading, data: userData } = useUserList(listParams);
  const [show, setShow] = useState("");
  const [data, setData] = useState<ProjectData | null>(null);
  const [searchuser, setsearchuser] = useState("");
  const { Search } = Input;
  const handleSearchInputChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setsearchuser(event?.target?.value);
  };
  const apiData = useMemo(() => {
    if (userData?.message)
      message.error(`Error in user list => ${userData?.message}`);
    return userData?.result;
  }, [userData]);
  const onEdit = (values: any) => {
    setData(values);
    setShow("AddEditUser");
  };
  // console.log("UserDataUserData = ", userData);

  const columns: any = [
    {
      title: "S. No.",
      dataIndex: "serial",
      key: "serial",
      width: "2%",
      render: (text: string, record: any) => (
        <div className="pl-4">{record?.sno}</div>
      ),
    },
    {
      sorter: (a: any, b: any) => a.Name - b.Name,
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "10%",
      render: (text: any, record: any) => record?.name,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      width: "10%",
      sorter: (a: any, b: any) => a.email.localeCompare(b.email),
      render: (text: string, record: any) => (
        <>
          <Typography.Paragraph
            title={record?.email}
            ellipsis
            copyable={{
              text: record?.email,
              onCopy: () => handleCopy(record?.email),
            }}
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            {record?.email}
          </Typography.Paragraph>
        </>
        // </Link>
      ),
    },

    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "2%",
      render: (text: string, record: any) => (
        <>
          {record?.isActive ? (
            <Tag icon={<CheckCircleOutlined />}  color="success">
              Active
            </Tag>
          ) : (
            <Tag icon={<CloseCircleOutlined />} color="error">
              InActive
            </Tag>
          )}
        </>
      ),
    },

    // {
    //   title: "",
    //   dataIndex: "actions",
    //   key: "description",
    //   width: "1%",
    //   render: (text: any, record: any) => (
    //     <>
    //       <Row justify={"end"}>
    //         <FormOutlined
    //           className="capTask-edit-btn"
    //           onClick={() => onEdit(record)}
    //         />
    //       </Row>
    //     </>
    //   ),
    // },
  ];

 
  const changeListParams = (key: string, value: any) => {
    setListParams((p: any) => {
      p[key] = value;
      return p;
    });
  };
  const [refresh, setRefresh] = useState<boolean>(false);
  const onChangeTable: TableProps<any>["onChange"] = (paging: any) => {
    console.log("pagingData = ",paging);
    
    if (paging?.current !== undefined && paging?.pageSize !== undefined) {
      changeListParams("start", (paging.current - 1) * paging.pageSize);
      changeListParams("length", paging.pageSize);
      setRefresh(!refresh);
    }
  };
  const pageSizeOption = ["15", "25", "50", "100"];
  // console.log("listParams = ",listParams);
  
  return (
    <>
      <Row
        gutter={[24, 16]}
        justify={"space-between"}
        style={{ marginBottom: 6 }}
      >
        <Col>
          <Link to={"/setting"}>
            <ArrowLeftOutlined className="BreadcrumbLink pe-2" />
          </Link>
        </Col>
        <Col>
          <Breadcrumb
            items={[
              {
                title: (
                  <Link to={"/dashboard"} className="BreadcrumbLink">
                    Home
                  </Link>
                ),
              },
              {
                title: (
                  <Link to={"/setting"} className="BreadcrumbLink">
                    Setting
                  </Link>
                ),
              },
              {
                title: "User",
              },
            ]}
          />
        </Col>
      </Row>
      <Row className="roundedCornerSmall bg-white" style={{ padding: 16 }}>
        <Col span={24}>
          <Row justify={"space-between"}>
            <Col span={18}>
              <Button
                icon={<PlusOutlined />}
                className="capTask-btn btn-clr-primary btn-primary-md mb-1"
                size="small"
                onClick={() => {
                  setShow("AddEditUser");
                  setData(null);
                }}
              >
                User
              </Button>
            </Col>
            <Col span={6}>
              <Row justify={"end"} gutter={[12, 0]}>
                <Col span={10}>
                  {/* <Select
              placeholder="Role"
              mode='multiple'
              className='w100 capTask-custom-select capTask-search'
                options={[
                  {
                    label: "Staff",
                    value: 'Staff',
                  },
                  {
                    label: "Admin",
                    value: 'Admin',
                  },
                  {
                    label: "SuperAdmin",
                    value: 'SuperAdmin',
                  },
                ]}
              /> */}
                </Col>
                <Col span={12}>
                  <Search
                    size="small"
                    placeholder="Search..."
                    allowClear
                    onSearch={(value:string)=>{
                      setListParams((pre)=>({
                        ...pre,
                        name:value
                      }))
                      
                    }}
                    onChange={handleSearchInputChange}
                    className="mb-1 w100"
                  />
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>

        <Col span={24}>
          {isLoading ? (
            <TableSkeleton
            className="capTask-custom-table mt-3"
              columns={columns}
              scroll={{ y: "70vh" }}
              style={{
                scrollbarWidth: "none",
                borderTop: "solid 1px #b2bec3",
              }}
            />
          ) : (
            <Table
              className="capTask-custom-table mt-3 project-table"
              size="small"
              onChange={onChangeTable}
              pagination={{
                total:apiData?.totalRecords,
                showTotal: (total: any, range: any) =>
                  `${range[0]}-${range[1]} of ${total} items`,
                current: listParams.start / listParams.length + 1,
                pageSize: listParams.length,
                showSizeChanger: true,
                pageSizeOptions: pageSizeOption,
                position: ["bottomRight"],
              }}
              scroll={{ y: "70vh" }}
              columns={columns}
              dataSource={apiData?.items}
              locale={{
                emptyText: (
                  <img
                    style={{ paddingTop: "40px", width: 140 }}
                    // src={process.env.PUBLIC_URL + "/noTask.svg"}
                    src={process.env.PUBLIC_URL + "/img-antd.svg"}
                    alt="No Task"
                  />
                ),
              }}
            />
          )}
        </Col>
      </Row>

      {show === "AddEditUser" && (
        <AddEditUser onDismiss={(rec:boolean) =>{
          if(rec)
            {
            } 
          setShow("")
        }} data={data} />
      )}
    </>
  );
};

export default UserDetail;
