import { DragDropContext } from "@hello-pangea/dnd";
import DroppableColumn from "./DroppableColumn";
import { GRID_VIEW } from "../../constants/commonConstants";

const DragableBoard = (props: any) => {
  const tasks = props?.tasks;

  const getSprintData = (status: any, sprintData: any) => {
    
    if (status && sprintData) {
      
      return sprintData?.find((t: any) => t?.id === status);

    }
    };


    



  return (
    <DragDropContext onDragEnd={props?.onDragEnd}>
      <div
        style={{
          display: props?.viewMode === GRID_VIEW ? "flex" : "block",
          // overflowX: "auto",
        //  scrollbarWidth: "none",
      
          marginLeft: "19px",
          marginRight: "10px",
          // minHeight:400,
          // height:`calc(103vh - 260px)`,
          // overflowY:'auto',
          overflowX: "scroll",
          height: "74vh",
          paddingRight: "16px",
          cursor:'n-resize',
        
         
        }}      
      
      >
        {props?.viewMode === GRID_VIEW ? (
          <div></div>
        ) : (
        

      null
        )}

        {/* overflowX:"scroll" */}
        {tasks &&
          Object.keys(tasks)?.map((status, i) => (
            <DroppableColumn
              i={i}
              key={status}
              status={status}
              page={props?.page}
              tasks={[...tasks?.[status]]}
              viewMode={props?.viewMode}
              onDragEnd={props?.onDragEnd}
              showLoading={props?.showLoading}
              sprintData={getSprintData(status, props?.sprintData)}
              onSelectDropDown={props?.onSelectDropDown}
              onClick={props?.onClick}
            />
          ))}
      </div>
    </DragDropContext>
  );
};

export default DragableBoard;
